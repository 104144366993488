import React, { useState } from "react";
import { Nav } from "react-bootstrap";
import { Category } from "../../constants/types";
import Stonks from "./Stonks";
import SubcategoryMenu from "./SubcategoryMenu";

interface Props {
    categories: Category[];
    showCategoryMenu: boolean;
}

const CategoryMenuMobile: React.FC<Props> = ({
    categories,
    showCategoryMenu,
}) => {
    const [activeCategory, setActiveCategory] = useState<Category | null>(null);

    const onClickHandler = (category: Category) => {
        if (activeCategory && category.id === activeCategory.id) {
            setActiveCategory(null);
        } else {
            setActiveCategory(category);
        }
    };
    return (
        <Nav className="d-block d-md-none px-2">
            {showCategoryMenu ? (
                <>
                    {categories.map(
                        (category) =>
                            category.parent === null && (
                                <div key={category.id}>
                                    <Nav.Link
                                        className={
                                            activeCategory &&
                                            category.id === activeCategory.id
                                                ? "text-warning"
                                                : ""
                                        }
                                        onClick={(
                                            e: React.MouseEvent<
                                                HTMLAnchorElement,
                                                MouseEvent
                                            >
                                        ) => onClickHandler(category)}
                                    >
                                        <h5>{category.name}</h5>
                                    </Nav.Link>
                                    {activeCategory &&
                                        category.id === activeCategory.id && (
                                            <SubcategoryMenu
                                                activeCategory={category}
                                            />
                                        )}
                                </div>
                            )
                    )}
                    {activeCategory === null && <Stonks />}
                </>
            ) : null}
        </Nav>
    );
};

export default CategoryMenuMobile;
