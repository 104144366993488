import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, Col, Row } from "react-bootstrap";
import { countImages, listImages } from "../../actions/imageActions";
import { Image as ImageType } from "../../constants/types";
import { ImageActionState, ImageListState } from "../../reducers/imageReducers";
import { addMessage } from "../../actions/messageActions";
import Loader from "../Loader";
import ModalComponent from "../layout/ModalComponent";
import ImageUpload from "./ImageUpload";
import {
    IMAGE_DELETE_REQUEST,
    IMAGE_UPLOAD_REQUEST,
} from "../../constants/imageConstants";
import ImageGalleryList from "./ImageGalleryList";
import BackendFilteredComponent from "../layout/BackendFilteredComponent";
import { RootState } from "../../store";

interface Props {
    title?: string;
    selectable?: boolean;
    images?: ImageType[] | null;
    handleSelect?: (image: ImageType) => void;
}

const ProductImages: React.FC<Props> = ({
    title,
    selectable = false,
    handleSelect,
}) => {
    const [showImageUploadModal, toggleImageUploadModal] = useState(false);
    const dispatch = useDispatch();

    const imageAction: ImageActionState = useSelector(
        (state: RootState) => state.imageAction
    );
    const {
        loading: loadingAction,
        success,
        error: errorAction,
        actionType,
    } = imageAction;

    const imageList: ImageListState = useSelector(
        (state: RootState) => state.imageList
    );
    const { loading, error, images, count } = imageList;

    useEffect(() => {
        if (!count) {
            dispatch(countImages());
        }
    }, [dispatch, count]);

    useEffect(() => {
        if (actionType === IMAGE_DELETE_REQUEST) {
            if (errorAction) {
                dispatch(
                    addMessage({
                        text: errorAction,
                        variant: "danger",
                        title: "Image Delete Error",
                    })
                );
            } else if (success) {
                dispatch(
                    addMessage({
                        text: "Image Removed",
                        variant: "success",
                        title: "Success",
                    })
                );
            }
        }

        if (actionType === IMAGE_UPLOAD_REQUEST) {
            if (errorAction) {
                dispatch(
                    addMessage({
                        text: errorAction,
                        variant: "danger",
                        title: "Image Upload Error",
                    })
                );
            } else if (success) {
                dispatch(
                    addMessage({
                        text: "Image Added",
                        variant: "success",
                        title: "Success",
                    })
                );
            }
        }
    }, [dispatch, error, errorAction, success, actionType]);

    const listImagesHandler = (start: number, limit: number) => {
        dispatch(listImages(start, limit));
    };

    return (
        <>
            {loading || loadingAction ? (
                <Loader />
            ) : error ? (
                <h2 className="text-danger">{error}</h2>
            ) : (
                <Row
                    className="mt-3 m-auto"
                    style={{
                        maxWidth: "100%",
                        minWidth: "95%",
                    }}
                >
                    <Card className="w-100">
                        <ModalComponent
                            showModalComponent={showImageUploadModal}
                            toggleModalComponent={toggleImageUploadModal}
                        >
                            <ImageUpload
                                toggleModalComponent={toggleImageUploadModal}
                            />
                        </ModalComponent>

                        <Card.Header className="align-items-center">
                            <Row className="align-items-center">
                                <Col>
                                    <h5 className="m-0">Image Gallery</h5>
                                </Col>
                                <Col className="text-right">
                                    <Button
                                        className=""
                                        variant="primary"
                                        onClick={(e) =>
                                            toggleImageUploadModal(true)
                                        }
                                    >
                                        <i className="fas fa-plus"></i> Add
                                        Image
                                    </Button>
                                </Col>
                            </Row>
                        </Card.Header>
                        <Card.Body>
                            {images ? (
                                <BackendFilteredComponent
                                    count={count || 0}
                                    loadedItems={images.length}
                                    loading={loading}
                                    pageAction={listImagesHandler}
                                    itemsPerPageOptions={[12]}
                                >
                                    <ImageGalleryList
                                        images={images}
                                        selectable={selectable}
                                        handleSelect={handleSelect}
                                    />
                                </BackendFilteredComponent>
                            ) : (
                                <h5 className="text-info text-center">
                                    No Images
                                </h5>
                            )}
                        </Card.Body>
                    </Card>
                </Row>
            )}
        </>
    );
};

export default ProductImages;
