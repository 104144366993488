import axios from "axios";
import { API_URL, errorResponseHandler } from "../constants/helpers";
import {
    PRODUCT_REVIEWS_FAIL,
    PRODUCT_REVIEWS_REQUEST,
    PRODUCT_REVIEWS_SUCCESS,
    REVIEW_ACTION_RESET,
    REVIEW_CREATE_FAIL,
    REVIEW_CREATE_REQUEST,
    REVIEW_CREATE_SUCCESS,
    REVIEW_DELETE_FAIL,
    REVIEW_DELETE_REQUEST,
    REVIEW_DELETE_SUCCESS,
    REVIEW_DETAILS_FAIL,
    REVIEW_DETAILS_REQUEST,
    REVIEW_DETAILS_RESET,
    REVIEW_DETAILS_SUCCESS,
    REVIEW_LIST_FAIL,
    REVIEW_LIST_REQUEST,
    REVIEW_LIST_RESET,
    REVIEW_LIST_SUCCESS,
    REVIEW_UPDATE_FAIL,
    REVIEW_UPDATE_REQUEST,
    REVIEW_UPDATE_SUCCESS,
    USER_REVIEWS_FAIL,
    USER_REVIEWS_REQUEST,
    USER_REVIEWS_SUCCESS,
} from "../constants/reviewConstants";
import { AsyncActionType, GeneralActionType } from "../constants/types";
import { addMessage } from "./messageActions";

export const getReviewList: AsyncActionType = () => async (dispatch) => {
    try {
        dispatch({ type: REVIEW_LIST_REQUEST });
        const { data } = await axios.get(
            `${API_URL}reviews?_sort=updated_at:DESC`
        );
        dispatch({
            type: REVIEW_LIST_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: REVIEW_LIST_FAIL,
            payload: errorResponseHandler(error),
        });
        dispatch(
            addMessage({
                text: errorResponseHandler(error),
                variant: "danger",
                title: "Review List Error",
            })
        );
    }
};

export const getProductReviews: AsyncActionType = (id) => async (dispatch) => {
    try {
        dispatch({ type: PRODUCT_REVIEWS_REQUEST });
        const { data } = await axios.get(`${API_URL}reviews?product=${id}`);
        dispatch({
            type: PRODUCT_REVIEWS_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: PRODUCT_REVIEWS_FAIL,
            payload: errorResponseHandler(error),
        });
        dispatch(
            addMessage({
                text: errorResponseHandler(error),
                variant: "danger",
                title: "Review List Error",
            })
        );
    }
};

export const getUserReviews: AsyncActionType = (id) => async (dispatch) => {
    try {
        dispatch({ type: USER_REVIEWS_REQUEST });
        const { data } = await axios.get(`${API_URL}reviews?user=${id}`);
        dispatch({
            type: USER_REVIEWS_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: USER_REVIEWS_FAIL,
            payload: errorResponseHandler(error),
        });
        dispatch(
            addMessage({
                text: errorResponseHandler(error),
                variant: "danger",
                title: "Review List Error",
            })
        );
    }
};

export const resetReviewList: GeneralActionType = () => (dispatch) => {
    dispatch({ type: REVIEW_LIST_RESET });
};

export const getReviewDetails: AsyncActionType =
    (reviewId) => async (dispatch) => {
        try {
            dispatch({ type: REVIEW_DETAILS_REQUEST });
            const { data } = await axios.get(`${API_URL}reviews/${reviewId}`);
            dispatch({
                type: REVIEW_DETAILS_SUCCESS,
                payload: data,
            });
        } catch (error) {
            dispatch({
                type: REVIEW_DETAILS_FAIL,
                payload: errorResponseHandler(error),
            });
            dispatch(
                addMessage({
                    text: errorResponseHandler(error),
                    variant: "danger",
                    title: "Review Details Error",
                })
            );
        }
    };

export const resetReviewDetails: GeneralActionType = () => (dispatch) => {
    dispatch({ type: REVIEW_DETAILS_RESET });
};

export const createReview: AsyncActionType =
    (reviewData) => async (dispatch, getState) => {
        try {
            dispatch({
                type: REVIEW_CREATE_REQUEST,
            });

            const {
                userAuth: { userInfo },
            } = getState();

            const config = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${userInfo?.jwt}`,
                },
            };
            const { data } = await axios.post(
                `${API_URL}reviews`,
                { ...reviewData, user: userInfo?.user.id },
                config
            );
            dispatch({
                type: REVIEW_CREATE_SUCCESS,
                payload: data,
            });
        } catch (error) {
            dispatch({
                type: REVIEW_CREATE_FAIL,
                payload: errorResponseHandler(error),
            });
            dispatch(
                addMessage({
                    text: errorResponseHandler(error),
                    variant: "danger",
                    title: "Review Create Error",
                })
            );
        }
    };

export const editMyReview: AsyncActionType =
    (reviewId, updateData) => async (dispatch, getState) => {
        try {
            dispatch({
                type: REVIEW_UPDATE_REQUEST,
            });

            const {
                userAuth: { userInfo },
            } = getState();

            const config = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${userInfo?.jwt}`,
                },
            };
            const { data } = await axios.put(
                `${API_URL}my-reviews/${reviewId}`,
                updateData,
                config
            );
            dispatch({
                type: REVIEW_UPDATE_SUCCESS,
                payload: data,
            });
        } catch (error) {
            dispatch({
                type: REVIEW_UPDATE_FAIL,
                payload: errorResponseHandler(error),
            });
            dispatch(
                addMessage({
                    text: errorResponseHandler(error),
                    variant: "danger",
                    title: "Review Update Error",
                })
            );
        }
    };

export const deleteReview: AsyncActionType =
    (reviewId) => async (dispatch, getState) => {
        try {
            dispatch({
                type: REVIEW_DELETE_REQUEST,
            });

            const {
                userAuth: { userInfo },
            } = getState();

            const config = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${userInfo?.jwt}`,
                },
            };
            const { data } = await axios.delete(
                `${API_URL}reviews/${reviewId}`,
                config
            );
            dispatch({
                type: REVIEW_DELETE_SUCCESS,
                payload: data,
            });
        } catch (error) {
            dispatch({
                type: REVIEW_DELETE_FAIL,
                payload: errorResponseHandler(error),
            });
            dispatch(
                addMessage({
                    text: errorResponseHandler(error),
                    variant: "danger",
                    title: "Review Delete Error",
                })
            );
        }
    };

export const deleteMyReview: AsyncActionType =
    (reviewId) => async (dispatch, getState) => {
        try {
            dispatch({
                type: REVIEW_DELETE_REQUEST,
            });

            const {
                userAuth: { userInfo },
            } = getState();

            const config = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${userInfo?.jwt}`,
                },
            };
            const { data } = await axios.delete(
                `${API_URL}my-reviews/${reviewId}`,
                config
            );
            dispatch({
                type: REVIEW_DELETE_SUCCESS,
                payload: data,
            });
        } catch (error) {
            dispatch({
                type: REVIEW_DELETE_FAIL,
                payload: errorResponseHandler(error),
            });
            dispatch(
                addMessage({
                    text: errorResponseHandler(error),
                    variant: "danger",
                    title: "Review Delete Error",
                })
            );
        }
    };

export const resetReviewAction: GeneralActionType = () => (dispatch) => {
    dispatch({ type: REVIEW_ACTION_RESET });
};
