import React from "react";
import { Col } from "react-bootstrap";

interface Props {
    showSidebar: boolean;
    toggleSidebar: (val: boolean) => void;
}

const SidebarToggle: React.FC<Props> = ({ showSidebar, toggleSidebar }) => {
    return (
        <Col md={4} lg={3} style={{ cursor: "pointer" }}>
            <h6
                className="text-info ml-2 mb-0"
                onClick={(e) => toggleSidebar(!showSidebar)}
            >
                {`${showSidebar ? "Hide" : "Show"} Filters `}
                {showSidebar ? (
                    <>
                        <i className="fas fa-chevron-left d-none d-md-inline" />
                        <i className="fas fa-chevron-up d-inline d-md-none" />
                    </>
                ) : (
                    <>
                        <i className="fas fa-chevron-right d-none d-md-inline" />
                        <i className="fas fa-chevron-down d-inline d-md-none" />
                    </>
                )}
            </h6>
        </Col>
    );
};

export default SidebarToggle;
