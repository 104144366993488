import React, { ChangeEvent } from "react";
import { Category } from "../../constants/types";
import FormField from "../FormField";
import CategorySelector from "./CategorySelector";

interface Props {
    categoryId?: number;
    name: string;
    setName: (name: string) => void;
    categories: Category[];
    parent: number | null;
    setParent: (id: number) => void;
}

const ProductFieldForm: React.FC<Props> = ({
    categoryId,
    name,
    setName,
    categories,
    parent,
    setParent,
}) => {
    return (
        <>
            <FormField
                controlId="name"
                label="Name"
                value={name}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setName(e.target.value)
                }
            />
            <CategorySelector
                label="Parent"
                parentOnly
                targetId={parent}
                selfId={categoryId}
                categories={categories}
                setCategory={setParent}
            />
        </>
    );
};

export default ProductFieldForm;
