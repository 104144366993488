import React from "react";
import { Link } from "react-router-dom";
import { Badge, Card } from "react-bootstrap";
import Rating from "../review/Rating";
import { Product } from "../../constants/types";

interface Props {
    product: Product;
}

const ProductCard: React.FC<Props> = ({ product }) => {
    const averageRating =
        product.reviews &&
        product.reviews.reduce((r: any, c: any) => r + c.rating, 0) /
            product.reviews.length;
    return (
        <Card className="shadow mb-2 mb-md-3 p-3 w-100 text-center card-hover bg-white">
            <Link to={`/product/${product.id}`} style={{ height: "65%" }}>
                <Card.Img
                    src={
                        product.images != null
                            ? product.images[0]?.formats?.thumbnail?.url ||
                              product.images[0]?.url ||
                              "/images/product-icon.png"
                            : "/images/product-icon.png"
                    }
                    variant="top"
                    // style={{ maxHeight: "100%", maxWidth: "260px" }}
                    style={{
                        maxWidth: "100%",
                        maxHeight: "260px",
                        width: "auto",
                    }}
                />
            </Link>
            <Card.Body>
                <Link to={`/product/${product.id}`}>
                    <Card.Title as="div">
                        <strong
                            className={`${
                                product.stock != null &&
                                product.stock <= 0 &&
                                "text-secondary"
                            }`}
                        >
                            {product.name}
                        </strong>
                    </Card.Title>
                </Link>
            </Card.Body>
            <footer>
                <Card.Text as="div">
                    {product.stock != null && product.stock <= 0 && (
                        <Badge pill variant="info">
                            Out of Stock
                        </Badge>
                    )}
                    {product.reviews && (
                        <Rating
                            value={averageRating || 0}
                            text={`${product.reviews.length} reviews`}
                        />
                    )}
                </Card.Text>
                <br />
                <Card.Text as="h3">${product.price}</Card.Text>
            </footer>
        </Card>
    );
};

export default ProductCard;
