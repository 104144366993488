import React, { useEffect, useState } from "react";
import { Button, ButtonGroup, Card, Form } from "react-bootstrap";
import { match, useHistory, useLocation } from "react-router";
import { SpecificationFields } from "../../constants/types";
import { useDispatch, useSelector } from "react-redux";
import { loadURLParams, resetFilters } from "../../actions/filteringActions";
import { FilteringState } from "../../reducers/filteringReducers";
import { RootState } from "../../store";
import ProductFilteringSort from "./ProductFilteringSort";
import ProductFilteringPriceRange from "./ProductFilteringPriceRange";
import ProductFilteringCategoryList from "./ProductFilteringCategoryList";
import { CategoryListState } from "../../reducers/categoryReducers";
import ProductFilteringSpecification from "./ProductFilteringSpecification";

interface Props {
    specificationModel?: SpecificationFields[];
    match: match<{
        id?: string;
        searchValue?: string;
        params?: string;
    }>;
    showCategorySelector?: boolean;
    perPageOptions?: number[];
}

const ProductFilteringController: React.FC<Props> = ({
    specificationModel = [],
    match,
    showCategorySelector = false,
    perPageOptions,
}) => {
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const matchedParams = match.params.params?.split("&");

    const filtering: FilteringState = useSelector(
        (state: RootState) => state.filtering
    );
    const { filters } = filtering;

    const categoryList: CategoryListState = useSelector(
        (state: RootState) => state.categoryList
    );
    const { categories } = categoryList;

    const [currentFilters, setCurrentFilters] = useState(filters);

    const currentCategory = categories?.filter(
        (category) => category.id === Number(filters.category)
    )[0];

    useEffect(() => {
        dispatch(loadURLParams(matchedParams));
        //eslint-disable-next-line
    }, [match]);

    useEffect(() => {
        setCurrentFilters(filters);
    }, [filters]);

    const submitHandler = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        history.push(
            `${location.pathname.split("/filter/")[0]}/filter/${
                currentFilters.category
                    ? "&category=" + currentFilters.category
                    : ""
            }${currentFilters.stockOnly ? "&stock_gt=0" : ""}${
                currentFilters.price_gte
                    ? "&price_gte=" + currentFilters.price_gte
                    : ""
            }${
                currentFilters.price_lte
                    ? "&price_lte=" + currentFilters.price_lte
                    : ""
            }`
        );
    };

    const resetFilterHandler = () => {
        dispatch(resetFilters());
    };

    return (
        <Card className="shadow mb-2 mb-md-0">
            <Form onSubmit={(e) => submitHandler(e)}>
                <Card.Header>
                    <ProductFilteringSort perPageOptions={perPageOptions} />
                </Card.Header>
                <Card.Body>
                    <ProductFilteringPriceRange />
                    {showCategorySelector && <ProductFilteringCategoryList />}
                    {specificationModel.length >= 1 ? (
                        <ProductFilteringSpecification
                            specificationModel={specificationModel}
                        />
                    ) : (
                        currentCategory?.specificationModel &&
                        currentCategory?.specificationModel.length >= 1 && (
                            <ProductFilteringSpecification
                                specificationModel={
                                    currentCategory?.specificationModel
                                }
                            />
                        )
                    )}
                    <ButtonGroup className="w-100">
                        <Button variant="primary" type="submit">
                            Apply
                        </Button>
                        <Button
                            variant="warning"
                            onClick={(e) => resetFilterHandler()}
                        >
                            Clear
                        </Button>
                    </ButtonGroup>
                </Card.Body>
            </Form>
        </Card>
    );
};

export default ProductFilteringController;
