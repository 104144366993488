export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL";
export const USER_LOGOUT = "USER_LOGOUT";

export const USER_REGISTER_REQUEST = "USER_REGISTER_REQUEST";
export const USER_REGISTER_SUCCESS = "USER_REGISTER_SUCCESS";
export const USER_REGISTER_FAIL = "USER_REGISTER_FAIL";

export const USER_DETAILS_REQUEST = "USER_DETAILS_REQUEST";
export const USER_DETAILS_SUCCESS = "USER_DETAILS_SUCCESS";
export const USER_DETAILS_FAIL = "USER_DETAILS_FAIL";
export const USER_DETAILS_RESET = "USER_DETAILS_RESET";

export const USER_LIST_REQUEST = "USER_LIST_REQUEST";
export const USER_LIST_SUCCESS = "USER_LIST_SUCCESS";
export const USER_LIST_FAIL = "USER_LIST_FAIL";
export const USER_LIST_RESET = "USER_LIST_RESET";

export const USER_ACTION_RESET = "USER_ACTION_RESET";

export const USER_UPDATE_ME_REQUEST = "USER_UPDATE_ME_REQUEST";
export const USER_UPDATE_ME_SUCCESS = "USER_UPDATE_ME_SUCCESS";
export const USER_UPDATE_ME_FAIL = "USER_UPDATE_ME_FAIL";

export const USER_GET_FAVORITES_REQUEST = "USER_GET_FAVORITES_REQUEST";
export const USER_GET_FAVORITES_SUCCESS = "USER_GET_FAVORITES_SUCCESS";
export const USER_GET_FAVORITES_FAIL = "USER_GET_FAVORITES_FAIL";

export const USER_ADD_FAVORITE_REQUEST = "USER_ADD_FAVORITE_REQUEST";
export const USER_ADD_FAVORITE_SUCCESS = "USER_ADD_FAVORITE_SUCCESS";
export const USER_ADD_FAVORITE_FAIL = "USER_ADD_FAVORITE_FAIL";

export const USER_REMOVE_FAVORITE_REQUEST = "USER_REMOVE_FAVORITE_REQUEST";
export const USER_REMOVE_FAVORITE_SUCCESS = "USER_REMOVE_FAVORITE_SUCCESS";
export const USER_REMOVE_FAVORITE_FAIL = "USER_REMOVE_FAVORITE_FAIL";

export const USER_BLOCK_REQUEST = "USER_BLOCK_REQUEST";
export const USER_BLOCK_SUCCESS = "USER_BLOCK_SUCCESS";
export const USER_BLOCK_FAIL = "USER_BLOCK_FAIL";

export const USER_UNBLOCK_REQUEST = "USER_UNBLOCK_REQUEST";
export const USER_UNBLOCK_SUCCESS = "USER_UNBLOCK_SUCCESS";
export const USER_UNBLOCK_FAIL = "USER_UNBLOCK_FAIL";
