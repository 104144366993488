import React from "react";
import { Col, Row, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import { CartProduct } from "../../constants/types";

interface Props {
    item: CartProduct;
    addDecimals: (num: number) => number;
}

const OrderProductRow: React.FC<Props> = ({ item, addDecimals }) => {
    return (
        <Row className="text-center align-items-center">
            <Col md={2}>
                <Link to={`/product/${item.product}`}>
                    <Image
                        src={item.image || "/images/product-icon.png"}
                        alt={item.name}
                        fluid
                        rounded
                    />
                </Link>
            </Col>
            <Col className="mt-md-0 mt-3 px-0 px-lg-2">
                <Link to={`/product/${item.product}`}>{item.name}</Link>
            </Col>
            <Col md={4} className="mt-md-0 mt-3 ">
                <span className="text-secondary">
                    {item.qty} x ${item.price} ={" "}
                </span>
                ${item.price && item.qty && addDecimals(item.qty * item.price)}
            </Col>
        </Row>
    );
};

export default OrderProductRow;
