import React, { FormEvent, useState } from "react";
import { Button, ButtonGroup, Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { createReview } from "../../actions/reviewActions";
import ReviewFields from "./ReviewFields";

interface Props {
    productId: number;
}

const ReviewCreate: React.FC<Props> = ({ productId }) => {
    const dispatch = useDispatch();
    const [comment, setComment] = useState("");
    const [rating, setRating] = useState(0);

    const submitHandler = (e: FormEvent<HTMLElement>) => {
        e.preventDefault();
        dispatch(createReview({ comment, rating, product: productId }));
    };

    return (
        <>
            <h6 className="text-center">Create A Review</h6>
            <Form onSubmit={submitHandler}>
                <ReviewFields
                    comment={comment}
                    setComment={setComment}
                    rating={rating}
                    setRating={setRating}
                />
                <ButtonGroup>
                    <Button
                        type="submit"
                        variant="success"
                        onSubmit={submitHandler}
                    >
                        Create
                    </Button>
                </ButtonGroup>
            </Form>
        </>
    );
};

export default ReviewCreate;
