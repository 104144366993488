import React, { useEffect } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { listCategories } from "../../actions/categoryActions";
import { LinkContainer } from "react-router-bootstrap";
import AdminOnly from "../../components/admin/AdminOnly";
import GoBack from "../../components/layout/GoBack";
import Loader from "../../components/Loader";
import { CategoryListState } from "../../reducers/categoryReducers";
import CategoryCard from "../../components/category/CategoryCard";
import { RootState } from "../../store";

const CategoryListScreen: React.FC = () => {
    const dispatch = useDispatch();
    const categoryList: CategoryListState = useSelector(
        (state: RootState) => state.categoryList
    );
    const { loading, error, categories } = categoryList;
    useEffect(() => {
        if (categories === null || categories?.length === 0) {
            dispatch(listCategories());
        }
    }, [dispatch, categories]);
    return (
        <>
            <AdminOnly />
            <GoBack />
            <Row className="align-items-center">
                <Col>
                    <h4>Categories</h4>
                </Col>
                <Col className="text-right">
                    <LinkContainer to="/admin/category/create">
                        <Button variant="primary">
                            <i className="fas fa-plus" /> Add Category
                        </Button>
                    </LinkContainer>
                </Col>
            </Row>
            {loading ? (
                <Loader />
            ) : error ? (
                <h2 className="text-danger">{error}</h2>
            ) : (
                <>
                    {categories && categories.length > 0 ? (
                        <>
                            {categories.map(
                                (category) =>
                                    category.parent === null && (
                                        <Card
                                            className="mt-3"
                                            key={category.id}
                                        >
                                            <CategoryCard category={category} />
                                        </Card>
                                    )
                            )}
                        </>
                    ) : (
                        <h3 className="text-warning">No Categories</h3>
                    )}
                </>
            )}
        </>
    );
};

export default CategoryListScreen;
