export const defaultFilters = {
    _sort: "created_at:DESC",
    _limit: 24,
    stockOnly: false,
    price_gte: "",
    price_lte: "",
    category: "",
    // specification: [],
};

export const RESET_FILTERS = "RESET_FILTERS";
export const LOAD_URL_PARAMS = "LOAD_URL_PARAMS";
export const CHANGE_FILTERS = "CHANGE_FILTERS";
