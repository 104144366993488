import React from "react";
import { Col } from "react-bootstrap";

interface Props {
    showSideBar?: boolean;
}

const ContentMain: React.FC<Props> = ({ showSideBar = false, children }) => {
    return (
        <Col md={showSideBar ? 8 : 12} lg={showSideBar ? 9 : 12}>
            {children}
        </Col>
    );
};

export default ContentMain;
