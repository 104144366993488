import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, ButtonGroup } from "react-bootstrap";
import { updateCategory } from "../../actions/categoryActions";
import { SpecificationFields } from "../../constants/types";
import CategorySpecificationFieldRow from "./CategorySpecificationFieldRow";
import { CategoryActionState } from "../../reducers/categoryReducers";
import Loader from "../Loader";
import { RootState } from "../../store";

interface Props {
    initialModel: SpecificationFields[];
    categoryId: number;
}

const CategorySpecificationFields: React.FC<Props> = ({
    initialModel,
    categoryId,
}) => {
    const dispatch = useDispatch();
    const [specificationModel, setSpecificationModel] = useState<
        SpecificationFields[]
    >(initialModel || []);

    const categoryAction: CategoryActionState = useSelector(
        (state: RootState) => state.categoryAction
    );
    const { loading: actionLoading } = categoryAction;

    const newSpecificationRow: SpecificationFields = {
        fieldName: "Field",
        fieldType: "text",
        fieldOptions: "",
        searchable: false,
    };

    const handleFieldChange = (index: number, field: SpecificationFields) => {
        let newSpecificationModel = specificationModel;
        newSpecificationModel[index] = field;
        setSpecificationModel([...newSpecificationModel]);
    };

    const handleFieldRemove = (index: number) => {
        let newSpecificationModel = specificationModel;
        newSpecificationModel.splice(index, 1);
        setSpecificationModel([...newSpecificationModel]);
    };

    const handleApply = () => {
        dispatch(
            updateCategory(categoryId, {
                specificationModel,
            })
        );
    };

    return (
        <>
            <h4>Specification</h4>
            {actionLoading ? (
                <Loader />
            ) : (
                <>
                    {specificationModel.map(
                        (field: SpecificationFields, index: number) => (
                            <div key={index}>
                                <CategorySpecificationFieldRow
                                    field={field}
                                    index={index}
                                    handleFieldChange={handleFieldChange}
                                    handleFieldRemove={handleFieldRemove}
                                />
                            </div>
                        )
                    )}
                    <hr />
                    <ButtonGroup>
                        <Button
                            variant="success"
                            className="btn-sm"
                            onClick={(e) => handleApply()}
                        >
                            <i className="fas fa-check"></i>
                            <span className="d-block">Apply</span>
                        </Button>
                        <Button
                            variant="info"
                            className="btn-sm"
                            onClick={(e) =>
                                setSpecificationModel([
                                    ...specificationModel,
                                    newSpecificationRow,
                                ])
                            }
                        >
                            <i className="fas fa-plus"></i>
                            <span className="d-block">Add New Field</span>
                        </Button>
                        <Button variant="light" disabled>
                            {specificationModel.length}
                            {" Fields"}
                        </Button>
                    </ButtonGroup>
                    <hr />
                </>
            )}
        </>
    );
};

export default CategorySpecificationFields;
