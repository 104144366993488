import React, { useState, useEffect } from "react";

import { useDispatch } from "react-redux";
import { Button, ButtonGroup, Col, Row } from "react-bootstrap";
import ImageModal from "./ImageModal";
import { deleteImage } from "../../actions/imageActions";
import ImageWithLoading from "../layout/ImageWithLoading";
import { Image as ImageType } from "../../constants/types";
import { GENERIC_IMAGE } from "../../constants/genericConstants";

interface Props {
    images: ImageType[];
    selectable: boolean;
    handleSelect?: (image: ImageType) => void;
}

const ImageGalleryList: React.FC<Props> = ({
    images,
    selectable,
    handleSelect,
}) => {
    const dispatch = useDispatch();
    const [activeIndex, setActiveIndex] = useState(0);
    const [showImageModal, toggleImageModal] = useState(false);

    const handleDelete = (image: ImageType) => {
        dispatch(deleteImage(image.id));
    };

    useEffect(() => {
        setActiveIndex(0);
    }, [images]);
    return (
        <>
            <ImageModal
                title="Image Gallery"
                imageUrl={
                    images != null && images[activeIndex]?.url
                        ? images[activeIndex].url
                        : "/images/product-icon.png"
                }
                showImageModal={
                    images != null &&
                    images[activeIndex]?.url != null &&
                    showImageModal
                }
                toggleImageModal={toggleImageModal}
            />
            <Row className="text-center" style={{ maxWidth: "100%" }}>
                {images?.map((image, index) => (
                    <Col
                        key={image.id}
                        xl={2}
                        lg={3}
                        md={selectable ? 6 : 4}
                        sm={6}
                        xs={12}
                    >
                        <ImageWithLoading
                            src={
                                image?.formats?.thumbnail?.url != null
                                    ? image.formats.thumbnail.url
                                    : image.url || GENERIC_IMAGE
                            }
                            alt={image.name || "Image"}
                            fluid
                            className={`image-thumbnail border ${
                                index === activeIndex
                                    ? "border-info blur"
                                    : "border-dark faded"
                            } mt-3`}
                            onClick={(e) => setActiveIndex(index)}
                            style={{ maxWidth: "158px" }}
                        />
                        {activeIndex === index && (
                            <div
                                className="pt-3 d-flex justify-content-center"
                                style={{
                                    position: "absolute",
                                    width: "100%",
                                    height: "100%",
                                    bottom: 0,
                                    left: 0,
                                }}
                            >
                                <ButtonGroup
                                    aria-label="Button Group"
                                    className="mt-3"
                                    style={{
                                        width: "75%",
                                        margin: "auto",
                                    }}
                                >
                                    {selectable && handleSelect && (
                                        <Button
                                            variant="success"
                                            className="btn-sm"
                                            onClick={(e) =>
                                                handleSelect(
                                                    images[activeIndex]
                                                )
                                            }
                                        >
                                            <i className="fas fa-check" />
                                            <span className="d-block">SEL</span>
                                        </Button>
                                    )}
                                    <Button
                                        variant="info"
                                        className="btn-sm"
                                        onClick={(e) => toggleImageModal(true)}
                                    >
                                        <i className="fas fa-eye" />
                                        <span className="d-block">VIEW</span>
                                    </Button>
                                    <Button
                                        variant="danger"
                                        className="btn-sm"
                                        onClick={(e) =>
                                            handleDelete(images[activeIndex])
                                        }
                                    >
                                        <i className="fas fa-trash" />
                                        <span className="d-block">DEL</span>
                                    </Button>
                                </ButtonGroup>
                            </div>
                        )}
                    </Col>
                ))}
            </Row>
        </>
    );
};

export default ImageGalleryList;
