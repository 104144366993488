import {
    PRODUCT_REVIEWS_FAIL,
    PRODUCT_REVIEWS_REQUEST,
    PRODUCT_REVIEWS_SUCCESS,
    REVIEW_ACTION_RESET,
    REVIEW_CREATE_FAIL,
    REVIEW_CREATE_REQUEST,
    REVIEW_CREATE_SUCCESS,
    REVIEW_DELETE_FAIL,
    REVIEW_DELETE_REQUEST,
    REVIEW_DELETE_SUCCESS,
    REVIEW_DETAILS_FAIL,
    REVIEW_DETAILS_REQUEST,
    REVIEW_DETAILS_RESET,
    REVIEW_DETAILS_SUCCESS,
    REVIEW_LIST_FAIL,
    REVIEW_LIST_REQUEST,
    REVIEW_LIST_RESET,
    REVIEW_LIST_SUCCESS,
    REVIEW_UPDATE_FAIL,
    REVIEW_UPDATE_REQUEST,
    REVIEW_UPDATE_SUCCESS,
    USER_REVIEWS_FAIL,
    USER_REVIEWS_REQUEST,
    USER_REVIEWS_SUCCESS,
} from "../constants/reviewConstants";
import { GeneralStateIF, GenericAction, Review } from "../constants/types";

const reviewActionsInitialState = {};

export interface ReviewActionsState extends GeneralStateIF {
    actionType?: string;
}

export const reviewActionsReducer = (
    state: ReviewActionsState = reviewActionsInitialState,
    action: GenericAction
): ReviewActionsState => {
    switch (action.type) {
        case REVIEW_UPDATE_REQUEST:
            return { loading: true, actionType: REVIEW_UPDATE_REQUEST };
        case REVIEW_CREATE_REQUEST:
            return { loading: true, actionType: REVIEW_CREATE_REQUEST };
        case REVIEW_DELETE_REQUEST:
            return { loading: true, actionType: REVIEW_DELETE_REQUEST };
        case REVIEW_UPDATE_SUCCESS:
        case REVIEW_DELETE_SUCCESS:
        case REVIEW_CREATE_SUCCESS:
            return { ...state, loading: false, success: true };
        case REVIEW_UPDATE_FAIL:
        case REVIEW_DELETE_FAIL:
        case REVIEW_CREATE_FAIL:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case REVIEW_ACTION_RESET:
            return reviewActionsInitialState;
        default:
            return state;
    }
};

const reviewDetailsInitialState = {};

export interface ReviewDetailsState extends GeneralStateIF {
    review?: Review;
}

export const reviewDetailsReducer = (
    state: ReviewDetailsState = reviewDetailsInitialState,
    action: GenericAction
): ReviewDetailsState => {
    switch (action.type) {
        case REVIEW_DETAILS_REQUEST:
            return {
                loading: true,
            };
        case REVIEW_DETAILS_SUCCESS:
        case REVIEW_UPDATE_SUCCESS:
        case REVIEW_DELETE_SUCCESS:
        case REVIEW_CREATE_SUCCESS:
            return {
                loading: false,
                review: action.payload,
            };
        case REVIEW_DETAILS_FAIL:
            return {
                loading: false,
                error: action.payload,
            };
        case REVIEW_DETAILS_RESET:
            return reviewDetailsInitialState;
        default:
            return state;
    }
};

const reviewListInitialState = {
    reviews: [],
};

export interface ReviewListState extends GeneralStateIF {
    reviews?: Review[];
}

export const reviewListReducer = (
    state: ReviewListState = reviewListInitialState,
    action: GenericAction
): ReviewListState => {
    switch (action.type) {
        case PRODUCT_REVIEWS_REQUEST:
        case USER_REVIEWS_REQUEST:
        case REVIEW_LIST_REQUEST:
            return { loading: true };
        case PRODUCT_REVIEWS_SUCCESS:
        case USER_REVIEWS_SUCCESS:
        case REVIEW_LIST_SUCCESS:
            return {
                loading: false,
                reviews: action.payload,
            };
        case PRODUCT_REVIEWS_FAIL:
        case USER_REVIEWS_FAIL:
        case REVIEW_LIST_FAIL:
            return { loading: false, error: action.payload };
        case REVIEW_LIST_RESET:
            return reviewListInitialState;
        default:
            return state;
    }
};
