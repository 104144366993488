import React, { useEffect, useState } from "react";
import { ListGroup } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getProductReviews } from "../../actions/reviewActions";
import { Product } from "../../constants/types";
import {
    ReviewActionsState,
    ReviewListState,
} from "../../reducers/reviewReducers";
import { UserAuthState } from "../../reducers/userReducers";
import { RootState } from "../../store";
import Loader from "../Loader";
import ReviewCreate from "../review/ReviewCreate";
import ReviewList from "../review/ReviewList";

interface Props {
    product: Product;
}

const ProductReviews: React.FC<Props> = ({ product }) => {
    const dispatch = useDispatch();
    const [currentReviews, setCurrentReviews] = useState(product.reviews);

    const reviewList: ReviewListState = useSelector(
        (state: RootState) => state.reviewList
    );
    const { loading, error, reviews } = reviewList;

    const userAuth: UserAuthState = useSelector(
        (state: RootState) => state.userAuth
    );
    const { userInfo } = userAuth;

    const reviewActions: ReviewActionsState = useSelector(
        (state: RootState) => state.reviewActions
    );
    const { loading: actionLoading, success } = reviewActions;

    const userHasReview = (userId: number) =>
        reviews?.some(
            (uid) => typeof uid.user === "object" && uid.user?.id === userId
        );

    useEffect(() => {
        dispatch(getProductReviews(product.id));
        //eslint-disable-next-line
    }, [success]);

    useEffect(() => {
        if (reviews) {
            setCurrentReviews(reviews);
        }
    }, [dispatch, product, error, reviews]);

    return (
        <>
            {currentReviews != null && currentReviews.length > 0 ? (
                <ListGroup variant="flush">
                    <ReviewList
                        reviews={currentReviews}
                        loading={loading || false}
                        header="user"
                    />
                </ListGroup>
            ) : (
                <h5 className="text-center">No reviews</h5>
            )}
            <hr />
            {userInfo?.user?.id ? (
                <>
                    {actionLoading ? (
                        <Loader />
                    ) : (
                        <>
                            {userHasReview(userInfo?.user?.id) ? (
                                <h6 className="text-center">
                                    You have already reviewed this product!
                                </h6>
                            ) : (
                                <ReviewCreate productId={product.id || 0} />
                            )}
                        </>
                    )}
                </>
            ) : (
                <h6 className="text-center">
                    <Link to="/login" className="link">
                        Log in
                    </Link>{" "}
                    or{" "}
                    <Link to="/register" className="link">
                        Create an Account
                    </Link>{" "}
                    to add a review.
                </h6>
            )}
        </>
    );
};

export default ProductReviews;
