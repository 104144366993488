import React from "react";
import { Spinner } from "react-bootstrap";

interface Props {
    variant?: "small" | "medium" | "large" | null;
}

const Loader: React.FC<Props> = ({ variant }) => {
    return (
        <>
            {variant === "small" ? (
                <Spinner className="ml-3" animation="grow" size="sm" />
            ) : (
                <Spinner
                    animation="border"
                    role="status"
                    style={{
                        width: "100px",
                        height: "100px",
                        margin: "auto",
                        marginTop: "30px",
                        display: "block",
                    }}
                >
                    <span className="sr-only">Loading...</span>
                </Spinner>
            )}
        </>
    );
};

export default Loader;
