import React from "react";
import { Button, ButtonGroup, Form } from "react-bootstrap";

interface Props {
    name: string;
    setName: (name: string) => void;
    username: string;
    setUsername: (username: string) => void;
    email: string;
    setEmail: (email: string) => void;
    phone: string;
    setPhone: (phone: string) => void;
    submitHandler: (event: any) => void;
}

const ProfileEdit: React.FC<Props> = ({
    name,
    setName,
    username,
    setUsername,
    email,
    setEmail,
    phone,
    setPhone,
    submitHandler,
}) => {
    return (
        <Form onSubmit={submitHandler}>
            <Form.Group controlId="name">
                <Form.Label>Name</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Enter name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                ></Form.Control>
            </Form.Group>
            <Form.Group controlId="username">
                <Form.Label>Username</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Enter username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                ></Form.Control>
            </Form.Group>
            <Form.Group controlId="email">
                <Form.Label>Email</Form.Label>
                <Form.Control
                    type="email"
                    placeholder="Enter email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                ></Form.Control>
            </Form.Group>
            <Form.Group controlId="phone">
                <Form.Label>Phone</Form.Label>
                <Form.Control
                    type="tel"
                    placeholder="Enter phone"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                ></Form.Control>
            </Form.Group>
            <ButtonGroup className="w-100">
                <Button type="submit" variant="primary" onClick={submitHandler}>
                    Update
                </Button>
                <Button variant="danger">Reset Password</Button>
            </ButtonGroup>
        </Form>
    );
};

export default ProfileEdit;
