import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { UserAuthState } from "../../reducers/userReducers";
import { RootState } from "../../store";

interface Props {
    redirect?: string;
}

const AdminOnly: React.FC<Props> = ({ redirect = "/" }) => {
    const userAuth: UserAuthState = useSelector(
        (state: RootState) => state.userAuth
    );
    const { userInfo } = userAuth;
    let history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        if (userInfo?.user?.role?.type !== "admin") {
            history.push(redirect);
        }
    }, [dispatch, history, userInfo, redirect]);
    return <></>;
};

export default AdminOnly;
