import {
    defaultFilters,
    CHANGE_FILTERS,
    LOAD_URL_PARAMS,
    RESET_FILTERS,
} from "../constants/filteringConstants";
import { GeneralActionType } from "../constants/types";

export const loadURLParams: GeneralActionType =
    (matchedParams) => (dispatch, getState) => {
        const {
            filtering: { filters },
        } = getState();
        const loadedFilters = {
            _sort: filters._sort || defaultFilters._sort,
            _limit: filters._limit || defaultFilters._limit,
            stockOnly:
                matchedParams && matchedParams.indexOf("stock_gt=0") > -1,
            price_gte:
                (matchedParams != null &&
                    Number(
                        matchedParams
                            .filter((s: string) => s.includes("price_gte"))
                            .toString()
                            .split("=")[1]
                    )) ||
                defaultFilters.price_gte,
            price_lte:
                (matchedParams != null &&
                    Number(
                        matchedParams
                            .filter((s: string) => s.includes("price_lte"))
                            .toString()
                            .split("=")[1]
                    )) ||
                defaultFilters.price_lte,
            category:
                (matchedParams != null &&
                    Number(
                        matchedParams
                            .filter((s: string) => s.includes("category"))
                            .toString()
                            .split("=")[1]
                    )) ||
                defaultFilters.category,
            // specification: matchedSpecs || [],
        };
        dispatch({ type: LOAD_URL_PARAMS, payload: loadedFilters });
    };

export const changeFilters: GeneralActionType =
    (changedFilters) => (dispatch) => {
        dispatch({ type: CHANGE_FILTERS, payload: changedFilters });
    };

export const resetFilters: GeneralActionType = () => (dispatch) => {
    dispatch({ type: RESET_FILTERS });
};
