import React, { useState, Fragment } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { Category } from "../../constants/types";
import ModalComponent from "../layout/ModalComponent";
import CategoryCreate from "./CategoryCreate";

interface Props {
    label?: string;
    parentOnly?: boolean;
    selfId?: number;
    targetId: number | undefined | null;
    setCategory: (id: number) => void;
    categories: Category[];
}

const CategorySelector: React.FC<Props> = ({
    label = "Category",
    parentOnly = false,
    selfId,
    targetId,
    setCategory,
    categories,
}) => {
    const [showModalComponent, toggleModalComponent] = useState(false);
    return (
        <Form.Group>
            <Form.Label>{label}</Form.Label>
            <InputGroup>
                <Form.Control
                    as="select"
                    className="mr-sm-2"
                    id="category"
                    custom
                    value={targetId || 0}
                    onChange={(e) => setCategory(+e.target.value)}
                >
                    <option value={0}>None</option>
                    {categories &&
                        categories.map((categoryItem) => (
                            <Fragment key={categoryItem.id}>
                                {parentOnly ? (
                                    categoryItem.parent === null &&
                                    categoryItem.id !== selfId && (
                                        <option value={categoryItem.id}>
                                            {categoryItem.name}
                                        </option>
                                    )
                                ) : (
                                    <option value={categoryItem.id}>
                                        {categoryItem.name}
                                    </option>
                                )}
                            </Fragment>
                        ))}
                </Form.Control>
                {!parentOnly && (
                    <>
                        <ModalComponent
                            showModalComponent={showModalComponent}
                            toggleModalComponent={toggleModalComponent}
                        >
                            <CategoryCreate />
                        </ModalComponent>
                        <InputGroup.Append>
                            <Button
                                variant="success"
                                onClick={(e) => toggleModalComponent(true)}
                            >
                                <i className="fas fa-plus"></i>
                            </Button>
                        </InputGroup.Append>
                    </>
                )}
            </InputGroup>
        </Form.Group>
    );
};

export default CategorySelector;
