import {
    PRODUCT_ACTIONS_RESET,
    PRODUCT_COUNT_FAIL,
    PRODUCT_COUNT_REQUEST,
    PRODUCT_COUNT_RESET,
    PRODUCT_COUNT_SUCCESS,
    PRODUCT_CREATE_FAIL,
    PRODUCT_CREATE_REQUEST,
    PRODUCT_CREATE_SUCCESS,
    PRODUCT_DELETE_FAIL,
    PRODUCT_DELETE_REQUEST,
    PRODUCT_DELETE_SUCCESS,
    PRODUCT_DETAILS_FAIL,
    PRODUCT_DETAILS_REQUEST,
    PRODUCT_DETAILS_RESET,
    PRODUCT_DETAILS_SUCCESS,
    PRODUCT_EDIT_FAIL,
    PRODUCT_EDIT_REQUEST,
    PRODUCT_EDIT_SUCCESS,
    PRODUCT_FILTERED_CATEGORY_FAIL,
    PRODUCT_FILTERED_CATEGORY_REQUEST,
    PRODUCT_FILTERED_CATEGORY_SUCCESS,
    PRODUCT_LIST_FAIL,
    PRODUCT_LIST_IN_CATEGORY_FAIL,
    PRODUCT_LIST_IN_CATEGORY_REQUEST,
    PRODUCT_LIST_IN_CATEGORY_RESET,
    PRODUCT_LIST_IN_CATEGORY_SUCCESS,
    PRODUCT_LIST_REQUEST,
    PRODUCT_LIST_SUCCESS,
    PRODUCT_SEARCH_FAIL,
    PRODUCT_SEARCH_REQUEST,
    PRODUCT_SEARCH_SUCCESS,
} from "../constants/productConstants";
import axios from "axios";
import { API_URL, errorResponseHandler } from "../constants/helpers";
import { addMessage } from "./messageActions";
import { AsyncActionType, GeneralActionType } from "../constants/types";

const defaultParams = {
    excludeNotInCategory: "category_ne=0",
    sort: "_sort=created_at:DESC",
    limit: "_limit=24",
};

export const getLatestProducts: AsyncActionType = () => async (dispatch) => {
    try {
        dispatch({ type: PRODUCT_LIST_REQUEST });
        const { data } = await axios.get(
            `${API_URL}products?${defaultParams.excludeNotInCategory}&${defaultParams.sort}&_limit=8`
        );
        dispatch({
            type: PRODUCT_LIST_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: PRODUCT_LIST_FAIL,
            payload: errorResponseHandler(error),
        });
        dispatch(
            addMessage({
                text: errorResponseHandler(error),
                variant: "danger",
                title: "Product List Error",
            })
        );
    }
};

export const countProductList: AsyncActionType =
    (params = "") =>
    async (dispatch) => {
        try {
            dispatch({ type: PRODUCT_COUNT_REQUEST });
            const { data } = await axios.get(
                `${API_URL}products/count?${defaultParams.sort}${
                    params.length > 0 ? `&${params}` : ""
                }`
            );
            dispatch({
                type: PRODUCT_COUNT_SUCCESS,
                payload: data,
            });
        } catch (error) {
            dispatch({
                type: PRODUCT_COUNT_FAIL,
                payload: errorResponseHandler(error),
            });
            dispatch(
                addMessage({
                    text: errorResponseHandler(error),
                    variant: "danger",
                    title: "Product Count Error",
                })
            );
        }
    };

export const getProductList: AsyncActionType =
    (params = "", page = 1) =>
    async (dispatch, getState) => {
        const {
            filtering: { filters },
        } = getState();
        try {
            dispatch({ type: PRODUCT_LIST_REQUEST });
            const { data } = await axios.get(
                `${API_URL}products?_sort=${filters._sort}&_start=${
                    (page - 1) * filters._limit
                }&${defaultParams.excludeNotInCategory}&_limit=${
                    filters._limit
                }${params ? "&" + params : ""}`
            );
            dispatch({
                type: PRODUCT_LIST_SUCCESS,
                payload: data,
            });
        } catch (error) {
            dispatch({
                type: PRODUCT_LIST_FAIL,
                payload: errorResponseHandler(error),
            });
            dispatch(
                addMessage({
                    text: errorResponseHandler(error),
                    variant: "danger",
                    title: "Product List Error",
                })
            );
        }
    };

export const getProductDetails: AsyncActionType = (id) => async (dispatch) => {
    try {
        dispatch({ type: PRODUCT_DETAILS_REQUEST });
        const { data } = await axios.get(`${API_URL}products/${id}`);
        dispatch({
            type: PRODUCT_DETAILS_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: PRODUCT_DETAILS_FAIL,
            payload: errorResponseHandler(error),
        });
        dispatch(
            addMessage({
                text: errorResponseHandler(error),
                variant: "danger",
                title: "Product Details Error",
            })
        );
    }
};

export const createProduct: AsyncActionType =
    (newProduct) => async (dispatch, getState) => {
        try {
            dispatch({ type: PRODUCT_CREATE_REQUEST });
            const {
                userAuth: { userInfo },
            } = getState();

            const config = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${userInfo?.jwt}`,
                },
            };
            const { data } = await axios.post(
                `${API_URL}products`,
                newProduct,
                config
            );
            dispatch({
                type: PRODUCT_CREATE_SUCCESS,
                payload: data,
            });
        } catch (error) {
            dispatch({
                type: PRODUCT_CREATE_FAIL,
                payload: errorResponseHandler(error),
            });
            dispatch(
                addMessage({
                    text: errorResponseHandler(error),
                    variant: "danger",
                    title: "Product Create Error",
                })
            );
        }
    };

export const editProduct: AsyncActionType =
    (id, updatedProduct) => async (dispatch, getState) => {
        try {
            dispatch({ type: PRODUCT_EDIT_REQUEST });
            const {
                userAuth: { userInfo },
            } = getState();

            const config = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${userInfo?.jwt}`,
                },
            };
            const { data } = await axios.put(
                `${API_URL}products/${id}`,
                updatedProduct,
                config
            );
            dispatch({
                type: PRODUCT_EDIT_SUCCESS,
                payload: data,
            });
        } catch (error) {
            dispatch({
                type: PRODUCT_EDIT_FAIL,
                payload: errorResponseHandler(error),
            });
            dispatch(
                addMessage({
                    text: errorResponseHandler(error),
                    variant: "danger",
                    title: "Product Edit Error",
                })
            );
        }
    };

export const deleteProduct: AsyncActionType =
    (id) => async (dispatch, getState) => {
        try {
            dispatch({ type: PRODUCT_DELETE_REQUEST });
            const {
                userAuth: { userInfo },
            } = getState();

            const config = {
                headers: {
                    Authorization: `Bearer ${userInfo?.jwt}`,
                },
            };
            const { data } = await axios.delete(
                `${API_URL}products/${id}`,
                config
            );
            dispatch({
                type: PRODUCT_DELETE_SUCCESS,
                payload: data,
            });
        } catch (error) {
            dispatch({
                type: PRODUCT_DELETE_FAIL,
                payload: errorResponseHandler(error),
            });
            dispatch(
                addMessage({
                    text: errorResponseHandler(error),
                    variant: "danger",
                    title: "Product Delete Error",
                })
            );
        }
    };

export const resetProductActions: GeneralActionType = () => (dispatch) => {
    dispatch({ type: PRODUCT_ACTIONS_RESET });
};

export const resetGetProductDetails: GeneralActionType = () => (dispatch) => {
    dispatch({ type: PRODUCT_DETAILS_RESET });
};

export const countProductsInCategory: AsyncActionType =
    (id, params = "") =>
    async (dispatch, getState) => {
        try {
            dispatch({ type: PRODUCT_COUNT_REQUEST });
            const { data } = await axios.get(
                `${API_URL}products/count?_where[_or][0][category]=${id}&_where[_or][1][category.parent]=${id}${
                    params.length > 0 ? `&${params}` : ""
                }`
            );

            dispatch({
                type: PRODUCT_COUNT_SUCCESS,
                payload: data,
            });
        } catch (error) {
            dispatch({
                type: PRODUCT_COUNT_FAIL,
                payload: errorResponseHandler(error),
            });
            dispatch(
                addMessage({
                    text: errorResponseHandler(error),
                    variant: "danger",
                    title: "Product List Error",
                })
            );
        }
    };

export const getProductsInCategory: AsyncActionType =
    (id, params = "", page = 1) =>
    async (dispatch, getState) => {
        const {
            filtering: { filters },
        } = getState();
        try {
            dispatch({ type: PRODUCT_LIST_IN_CATEGORY_REQUEST });
            const { data } = await axios.get(
                `${API_URL}products?_where[_or][0][category]=${id}&_where[_or][1][category.parent]=${id}&_sort=${
                    filters._sort
                }&_start=${(page - 1) * filters._limit}&${
                    defaultParams.excludeNotInCategory
                }&_limit=${filters._limit}${params ? "&" + params : ""}`
            );

            dispatch({
                type: PRODUCT_LIST_IN_CATEGORY_SUCCESS,
                payload: data,
            });
        } catch (error) {
            dispatch({
                type: PRODUCT_LIST_IN_CATEGORY_FAIL,
                payload: errorResponseHandler(error),
            });
            dispatch(
                addMessage({
                    text: errorResponseHandler(error),
                    variant: "danger",
                    title: "Product List Error",
                })
            );
        }
    };

export const resetProductsInCategory: GeneralActionType = () => (dispatch) => {
    dispatch({ type: PRODUCT_LIST_IN_CATEGORY_RESET });
};

export const getFilteredProductsInCategory: AsyncActionType =
    (categoryId, params) => async (dispatch) => {
        try {
            dispatch({ type: PRODUCT_FILTERED_CATEGORY_REQUEST });
            const { data } = await axios.get(
                `${API_URL}products?category=${categoryId}&${params}`
            );

            dispatch({
                type: PRODUCT_FILTERED_CATEGORY_SUCCESS,
                payload: data,
            });
        } catch (error) {
            dispatch({
                type: PRODUCT_FILTERED_CATEGORY_FAIL,
                payload: errorResponseHandler(error),
            });
            dispatch(
                addMessage({
                    text: errorResponseHandler(error),
                    variant: "danger",
                    title: "Product List Error",
                })
            );
        }
    };

export const countSearchedProducts: AsyncActionType =
    (searchValue, params = "") =>
    async (dispatch, getState) => {
        try {
            dispatch({ type: PRODUCT_COUNT_REQUEST });
            let url = "";
            if (params) {
                url = `${API_URL}products/count?name_contains=${searchValue}&${defaultParams.excludeNotInCategory}&${params}`;
            } else {
                url = `${API_URL}products/count?name_contains=${searchValue}&${defaultParams.excludeNotInCategory}`;
            }
            const { data } = await axios.get(url);

            dispatch({
                type: PRODUCT_COUNT_SUCCESS,
                payload: data,
            });
        } catch (error) {
            dispatch({
                type: PRODUCT_COUNT_FAIL,
                payload: errorResponseHandler(error),
            });
            dispatch(
                addMessage({
                    text: errorResponseHandler(error),
                    variant: "danger",
                    title: "Product Search Error",
                })
            );
        }
    };

export const resetProductCount: GeneralActionType = () => (dispatch) => {
    dispatch({ type: PRODUCT_COUNT_RESET });
};

// TODO fix filter reducer when changing pages
export const searchProducts: AsyncActionType =
    (searchValue, params = "", page = 1) =>
    async (dispatch, getState) => {
        try {
            dispatch({ type: PRODUCT_SEARCH_REQUEST });
            const {
                filtering: { filters },
            } = getState();
            let url = `${API_URL}products?name_contains=${searchValue}&_sort=${
                filters._sort
            }&_start=${(page - 1) * filters._limit}&${
                defaultParams.excludeNotInCategory
            }&_limit=${filters._limit}${params ? "&" + params : ""}`;
            const { data } = await axios.get(url);

            dispatch({
                type: PRODUCT_SEARCH_SUCCESS,
                payload: data,
            });
        } catch (error) {
            dispatch({
                type: PRODUCT_SEARCH_FAIL,
                payload: errorResponseHandler(error),
            });
            dispatch(
                addMessage({
                    text: errorResponseHandler(error),
                    variant: "danger",
                    title: "Product Search Error",
                })
            );
        }
    };
