import React, { useState } from "react";
import { Button, ButtonGroup, Card, Col, Row } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { Category } from "../../constants/types";

interface Props {
    subcategories: Category[];
    setSubcategoryList: (arr: Category[]) => void;
}

const SubcategoryListOrder: React.FC<Props> = ({
    subcategories,
    setSubcategoryList,
}) => {
    const [draggedRow, setDraggedRow] = useState<null | number>(null);

    const dragStartHandler = (index: number) => {
        setDraggedRow(index);
    };

    const dragOverHandler = (index: number) => {
        if (draggedRow !== null && draggedRow < index) {
            handleMoveUp(index);
        }
        if (draggedRow !== null && draggedRow > index) {
            handleMoveDown(index);
        }
    };

    const dropHandler = () => {
        setDraggedRow(null);
    };

    const handleMoveUp = (index: number) => {
        if (index > 0) {
            setDraggedRow(index);
            let editedArr = subcategories;
            editedArr.splice(
                index - 1,
                2,
                subcategories[index],
                subcategories[index - 1]
            );
            setSubcategoryList([...editedArr]);
        }
    };

    const handleMoveDown = (index: number) => {
        if (index < subcategories.length - 1) {
            setDraggedRow(index);
            let editedArr = subcategories;
            editedArr.splice(
                index,
                2,
                subcategories[index + 1],
                subcategories[index]
            );
            setSubcategoryList([...editedArr]);
        }
    };

    const moveUpButtonHandler = (index: number) => {
        handleMoveUp(index);
        dropHandler();
    };

    const moveDownButtonHandler = (index: number) => {
        handleMoveDown(index);
        dropHandler();
    };

    return (
        <Card.Body className="p-1">
            {subcategories.map((subcategory, index) => (
                <Row
                    draggable
                    key={subcategory.id}
                    className="text-center  align-items-center
                    py-1 mx-0 border-top border-bottom px-md-0"
                    onDragStart={(e: any) => dragStartHandler(index)}
                    onDragOver={(e: any) => dragOverHandler(index)}
                    onDragEnd={(e: any) => dropHandler()}
                >
                    <Col sm={1} className="d-none d-sm-block">
                        <span className="text-secondary">{index + 1}</span>
                    </Col>
                    <Col sm={1} className="d-none d-sm-block">
                        <i
                            className={`fas ${
                                draggedRow === index
                                    ? "fa-dot-circle text-success"
                                    : draggedRow && draggedRow < index
                                    ? "fa-arrow-up text-info"
                                    : draggedRow && draggedRow > index
                                    ? "fa-arrow-down text-info"
                                    : "fa-arrows-alt-v text-primary"
                            }`}
                        />
                    </Col>
                    <Col
                        lg={8}
                        md={7}
                        sm={6}
                        xs={7}
                        className={`${draggedRow === index ? "text-info" : ""}`}
                    >
                        {subcategory.name}
                    </Col>
                    <Col lg={1} md={1} sm={2} xs={3}>
                        <ButtonGroup className="d-md-none">
                            <Button
                                variant="outline-info"
                                className="btn-sm"
                                onClick={(e) => moveUpButtonHandler(index)}
                            >
                                <i className="fas fa-arrow-up" />
                            </Button>
                            <Button
                                variant="outline-info"
                                className="btn-sm"
                                onClick={(e) => moveDownButtonHandler(index)}
                            >
                                <i className="fas fa-arrow-down" />
                            </Button>
                        </ButtonGroup>
                    </Col>
                    <Col lg={1} sm={2} xs={2}>
                        <LinkContainer
                            to={`/admin/category/${subcategory.id}/edit`}
                        >
                            <Button variant="outline-light" className="btn-sm">
                                <i className="fas fa-edit" />
                                <span className="d-block">EDIT</span>
                            </Button>
                        </LinkContainer>
                    </Col>
                    <hr></hr>
                </Row>
            ))}
        </Card.Body>
    );
};

export default SubcategoryListOrder;
