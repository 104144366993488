import React from "react";
import { Button, ButtonGroup, Col, ListGroup, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import Loader from "../Loader";
import Rating from "./Rating";
import { Review } from "../../constants/types";
import { deleteMyReview, deleteReview } from "../../actions/reviewActions";
import { UserAuthState } from "../../reducers/userReducers";
import { RootState } from "../../store";

interface Props {
    review: Review;
    loading: boolean;
    notLastItem: boolean;
    header: string;
}

const ReviewListItem: React.FC<Props> = ({
    review,
    loading,
    notLastItem,
    header,
}) => {
    const dispatch = useDispatch();
    const userAuth: UserAuthState = useSelector(
        (state: RootState) => state.userAuth
    );
    const { userInfo } = userAuth;

    const isOwner = (userId: number) => userId === userInfo?.user.id;
    const isAdmin = () => userInfo?.user?.role?.type === "admin";
    const deleteHandler = (reviewId: number) => {
        dispatch(deleteMyReview(reviewId));
    };
    const adminDeleteHandler = (reviewId: number) => {
        dispatch(deleteReview(reviewId));
    };
    return (
        <ListGroup.Item
            className={`${notLastItem && "border-bottom border-info"}`}
        >
            <Row>
                <Col sm={9} md={10}>
                    {loading ? (
                        <Loader variant="small" />
                    ) : (
                        <>
                            {(header === "product" || header === "both") &&
                                typeof review.product === "object" && (
                                    <Link to={`/product/${review.product?.id}`}>
                                        <p className="font-weight-bold text-primary mb-1">
                                            {review.product?.name}
                                        </p>
                                    </Link>
                                )}
                            {(header === "user" || header === "both") &&
                                typeof review.user === "object" && (
                                    <p className="font-weight-bold text-primary mb-1">
                                        {review.user?.name ||
                                            review.user?.username}
                                    </p>
                                )}
                        </>
                    )}
                    <Row>
                        <Col sm={6}>
                            <Rating value={review.rating || 0} />
                        </Col>
                        <Col sm={6}>
                            <small className="text-info">
                                {review.updated_at?.substring(0, 10)}
                            </small>
                        </Col>
                    </Row>
                </Col>
                <Col sm={3} md={2}>
                    {typeof review?.user === "object" &&
                        (isOwner(review.user?.id!) ? (
                            <ButtonGroup className="mt-3 mt-sm-0">
                                <LinkContainer
                                    to={`/reviews/${review.id}/edit`}
                                >
                                    <Button
                                        variant="primary"
                                        className="btn-sm"
                                    >
                                        <i className="fas fa-edit" />
                                        <span className="d-none d-sm-block">
                                            EDIT
                                        </span>
                                    </Button>
                                </LinkContainer>
                                <Button
                                    variant="danger"
                                    className="btn-sm"
                                    onClick={() => deleteHandler(review.id!)}
                                >
                                    <i className="fas fa-trash" />
                                    <span className="d-none d-sm-block">
                                        DEL
                                    </span>
                                </Button>
                            </ButtonGroup>
                        ) : (
                            isAdmin() && (
                                <Button
                                    variant="danger"
                                    className="btn-sm"
                                    onClick={() =>
                                        adminDeleteHandler(review.id!)
                                    }
                                >
                                    <i className="fas fa-times" />
                                    <span className="d-none d-sm-block">
                                        REMOVE
                                    </span>
                                </Button>
                            )
                        ))}
                </Col>
            </Row>
            <hr />
            <p className="mt-2 mb-1">{review.comment}</p>
        </ListGroup.Item>
    );
};

export default ReviewListItem;
