import React from "react";
import { Modal } from "react-bootstrap";

interface Props {
    showModalComponent: boolean;
    toggleModalComponent: (x: boolean) => void;
}

const ModalComponent: React.FC<Props> = (props) => {
    const { showModalComponent, toggleModalComponent } = props;
    const handleClose = () => {
        toggleModalComponent(false);
    };
    return (
        <Modal
            size="xl"
            centered
            show={showModalComponent}
            onHide={handleClose}
            className="w-100"
        >
            <Modal.Body className="d-flex justify-content-center">
                {props.children}
            </Modal.Body>
        </Modal>
    );
};

export default ModalComponent;
