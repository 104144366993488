import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { match } from "react-router";
import {
    countSearchedProducts,
    resetProductsInCategory,
    searchProducts,
} from "../../actions/productActions";
import Announcer from "../../components/layout/Announcer";
import ContentFooter from "../../components/layout/ContentFooter";
import ContentHeader from "../../components/layout/ContentHeader";
import ContentMain from "../../components/layout/ContentMain";
import ContentSidebar from "../../components/layout/ContentSidebar";
import GoBack from "../../components/layout/GoBack";
import SidebarToggle from "../../components/layout/SidebarToggle";
import Loader from "../../components/Loader";
import ProductCard from "../../components/product/ProductCard";
import ProductFilteringController from "../../components/product/ProductFIlteringController";
import ProductPagination from "../../components/product/ProductPagination";
import { FilteringState } from "../../reducers/filteringReducers";
import { ProductListState } from "../../reducers/productReducers";
import { RootState } from "../../store";

interface Props {
    match: match<{ searchValue?: string; params?: string; specs?: string }>;
}

const SearchProductsScreen: React.FC<Props> = ({ match }) => {
    const dispatch = useDispatch();
    const [showFilterMenu, toggleFilterMenu] = useState(!isMobile);
    const filtering: FilteringState = useSelector(
        (state: RootState) => state.filtering
    );
    const { filters } = filtering;
    const productList: ProductListState = useSelector(
        (state: RootState) => state.productList
    );
    const { loading, error, products } = productList;

    const [currentPage, setCurrentPage] = useState(1);
    useEffect(() => {
        dispatch(resetProductsInCategory());
        dispatch(
            countSearchedProducts(
                match.params.searchValue,
                match.params.params || ""
            )
        );
        dispatch(
            searchProducts(match.params.searchValue, match.params.params || "")
        );
        //eslint-disable-next-line
    }, [match]);

    useEffect(() => {
        dispatch(
            searchProducts(
                match.params.searchValue,
                match.params.params || "",
                currentPage
            )
        );
        //eslint-disable-next-line
    }, [currentPage, filters._limit, filters._sort]);

    return (
        <>
            <GoBack />
            <ContentHeader>
                <SidebarToggle
                    showSidebar={showFilterMenu}
                    toggleSidebar={toggleFilterMenu}
                />
                <ProductPagination
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    hideOnMobile
                />
            </ContentHeader>
            <Row>
                <ContentSidebar showSideBar={showFilterMenu}>
                    <ProductFilteringController
                        match={match}
                        showCategorySelector
                    />
                </ContentSidebar>
                <ContentMain showSideBar={showFilterMenu}>
                    <Row className="d-block d-md-none mb-2">
                        <ProductPagination
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                        />
                    </Row>
                    {loading ? (
                        <Loader />
                    ) : error ? (
                        <>
                            <h3>Unable to load products</h3>
                        </>
                    ) : (
                        <Row>
                            {products && products.length > 0 ? (
                                products.map((product) => (
                                    <Col
                                        key={product.id}
                                        sm={6}
                                        md={showFilterMenu ? 6 : 4}
                                        lg={4}
                                        xl={showFilterMenu ? 4 : 3}
                                        className="d-flex"
                                    >
                                        <ProductCard product={product} />
                                    </Col>
                                ))
                            ) : (
                                <Announcer text="No Products Found" />
                            )}
                        </Row>
                    )}
                </ContentMain>
            </Row>
            <ContentFooter>
                <ProductPagination
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                />
            </ContentFooter>
        </>
    );
};

export default SearchProductsScreen;
