import React, { useState, useEffect } from "react";
import { Button, ButtonGroup, Col, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { match, RouteComponentProps } from "react-router";
import { addMessage } from "../../actions/messageActions";
import {
    deleteProduct,
    editProduct,
    getProductDetails,
    resetProductActions,
} from "../../actions/productActions";
import AdminOnly from "../../components/admin/AdminOnly";
import GoBack from "../../components/layout/GoBack";
import Loader from "../../components/Loader";
import RichTextEditor from "../../components/product/RichTextEditor";
import DOMPurify from "dompurify";
import {
    PRODUCT_DELETE_REQUEST,
    PRODUCT_EDIT_REQUEST,
} from "../../constants/productConstants";
import {
    ProductActionsState,
    ProductDetailsState,
} from "../../reducers/productReducers";
import { Image } from "../../constants/types";
import ProductEditImages from "../../components/images/ProductEditImages";
import ProductFieldForm from "../../components/product/ProductFieldForm";
import { CategoryListState } from "../../reducers/categoryReducers";
import ProductSpecificationEdit from "../../components/product/ProductSpecificationEdit";
import { RootState } from "../../store";

interface Props extends RouteComponentProps {
    match: match<{ id: string }>;
}

const ProductEditScreen: React.FC<Props> = ({ match, history }) => {
    const productId = match.params.id;
    const dispatch = useDispatch();
    const productDetails: ProductDetailsState = useSelector(
        (state: RootState) => state.productDetails
    );
    const { loading, error, product } = productDetails;
    const productActions: ProductActionsState = useSelector(
        (state: RootState) => state.productActions
    );
    const {
        success,
        loading: actionLoading,
        error: actionError,
        actionType,
    } = productActions;
    const categoryList: CategoryListState = useSelector(
        (state: RootState) => state.categoryList
    );
    const { categories } = categoryList;

    const [name, setName] = useState("");
    const [price, setPrice] = useState(0);
    const [brand, setBrand] = useState("");
    const [category, setCategory] = useState(0);
    const [code, setCode] = useState("");
    const [countInStock, setCountInStock] = useState(0);
    const [description, setDescription] = useState("");
    const [images, setImages] = useState<Array<Image> | null>(null);
    const [specification, setSpecification] = useState({});

    useEffect(() => {
        dispatch(getProductDetails(productId));
        dispatch(resetProductActions);
    }, [dispatch, productId]);

    useEffect(() => {
        if (product) {
            setName(product.name || "");
            setPrice(product.price || 0);
            setBrand(product.brand || "");
            setCategory(
                (typeof product.category === "object" &&
                    product.category?.id) ||
                    0
            );
            setCode(product.code || "");
            setCountInStock(product.stock || 0);
            setDescription(product.description || "");
            if (product.images != null) {
                setImages(product.images || null);
            }
            setSpecification(product.specification || {});
        }
        if (success && actionType === PRODUCT_EDIT_REQUEST) {
            dispatch(
                addMessage({
                    text: "Product Updated",
                    variant: "success",
                })
            );
            dispatch(resetProductActions());
        }
        if (success && actionType === PRODUCT_DELETE_REQUEST) {
            dispatch(
                addMessage({
                    text: "Product Deleted",
                    variant: "success",
                })
            );
            history.push("/admin/productlist");
            dispatch(resetProductActions());
        }

        // eslint-disable-next-line
    }, [dispatch, product, error, actionError, success, actionType]);

    const submitHandler = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        dispatch(
            editProduct(productId, {
                name,
                price,
                brand,
                category: category !== 0 ? category : null,
                code,
                stock: countInStock,
                description: DOMPurify.sanitize(description),
                images,
                specification,
            })
        );
    };

    const deleteHandler = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        e.preventDefault();
        if (window.confirm("Are you sure?")) {
            dispatch(deleteProduct(productId));
        }
    };
    return (
        <>
            <AdminOnly />
            <GoBack link="/admin/productlist" />
            <h4>Edit Product</h4>
            {loading || actionLoading ? (
                <Loader />
            ) : error ? (
                <h2 className="text-danger">{error}</h2>
            ) : (
                <Form onSubmit={submitHandler}>
                    <Row>
                        <Col md={6} lg={7}>
                            <ProductFieldForm
                                name={name}
                                setName={setName}
                                price={price}
                                setPrice={setPrice}
                                code={code}
                                setCode={setCode}
                                brand={brand}
                                setBrand={setBrand}
                                countInStock={countInStock}
                                setCountInStock={setCountInStock}
                                categories={categories || []}
                                setCategory={setCategory}
                                categoryId={category || 0}
                            />
                        </Col>
                        <Col md={6} lg={5} className="mt-3 mt-md-0">
                            {product?.images != null && (
                                <ProductEditImages
                                    title={product.name}
                                    images={images}
                                    setImages={setImages}
                                />
                            )}
                        </Col>
                    </Row>
                    <Row>
                        <Col className="mt-3 mt-md-0">
                            <Form.Group controlId="description">
                                <Form.Label>Description</Form.Label>
                                <RichTextEditor
                                    description={description}
                                    setDescription={setDescription}
                                />
                            </Form.Group>

                            <ProductSpecificationEdit
                                specification={specification}
                                setSpecification={setSpecification}
                                categoryId={category}
                            />
                            <ButtonGroup>
                                <Button
                                    type="submit"
                                    variant="primary"
                                    onSubmit={submitHandler}
                                >
                                    Update
                                </Button>
                                <Button
                                    variant="danger"
                                    onClick={deleteHandler}
                                >
                                    Delete
                                </Button>
                            </ButtonGroup>
                        </Col>
                    </Row>
                </Form>
            )}
        </>
    );
};

export default ProductEditScreen;
