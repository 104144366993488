import React, { useState, useEffect, Fragment } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getCategoryDetails } from "../../actions/categoryActions";
import { SpecificationFields } from "../../constants/types";
import { CategoryDetailsState } from "../../reducers/categoryReducers";
import CategorySpecificationFields from "../category/CategorySpecificationFields";
import Loader from "../Loader";
import ModalComponent from "../layout/ModalComponent";
import ProductSpecificationEditFieldRow from "./ProductSpecificationEditFieldRow";
import { RootState } from "../../store";

interface Props {
    categoryId: number;
    specification: {};
    setSpecification: (specification: {}) => void;
}

const ProductSpecificationEdit: React.FC<Props> = ({
    categoryId,
    specification,
    setSpecification,
}) => {
    const dispatch = useDispatch();
    const categoryDetails: CategoryDetailsState = useSelector(
        (state: RootState) => state.categoryDetails
    );
    const { loading, category } = categoryDetails;

    const [showCategorySpecificationModal, toggleCategorySpecificationModal] =
        useState(false);

    const [specificationModel, setSpecificationModel] = useState<
        SpecificationFields[]
    >([]);
    useEffect(() => {
        if (categoryId !== 0) dispatch(getCategoryDetails(categoryId));
    }, [dispatch, categoryId]);

    useEffect(() => {
        setSpecificationModel(category?.specificationModel || []);
    }, [category]);
    return (
        <>
            <hr />
            <Row>
                <Col sm={10}>
                    {category ? (
                        <h4>{category?.name} Specification</h4>
                    ) : (
                        <h4>Specification</h4>
                    )}
                </Col>
                <Col sm={2}>
                    <Button
                        variant="info"
                        size="sm"
                        className="align-self-right"
                        onClick={(e) => toggleCategorySpecificationModal(true)}
                    >
                        <i className="fas fa-edit" /> Edit
                        <span className="d-none d-lg-inline"> Model</span>
                    </Button>
                </Col>
            </Row>
            {loading ? (
                <Loader />
            ) : (
                <>
                    {showCategorySpecificationModal && (
                        <ModalComponent
                            showModalComponent={showCategorySpecificationModal}
                            toggleModalComponent={
                                toggleCategorySpecificationModal
                            }
                        >
                            <Container>
                                <CategorySpecificationFields
                                    categoryId={categoryId}
                                    initialModel={
                                        category?.specificationModel || []
                                    }
                                />
                            </Container>
                        </ModalComponent>
                    )}
                    {specificationModel.map((field) => (
                        <Fragment key={field.fieldName}>
                            <ProductSpecificationEditFieldRow
                                field={field}
                                specification={specification}
                                setSpecification={setSpecification}
                            />
                        </Fragment>
                    ))}
                </>
            )}

            <hr />
        </>
    );
};

export default ProductSpecificationEdit;
