import React, { useState, useEffect, ChangeEvent } from "react";
import { Form, FormControl, InputGroup } from "react-bootstrap";

interface Props {
    data: any[];
    searchOptions: { path: string; title: string }[];
    setFilteredItems: (listItems: any[]) => void;
}

const SearchComponent: React.FC<Props> = ({
    data,
    searchOptions,
    setFilteredItems,
}) => {
    const [activeField, setActiveField] = useState(searchOptions[0]);
    const [value, setValue] = useState("");

    const resolvePath = (target: string, path: string) =>
        path
            .split(".")
            .reduce((previous, current: any) => previous[current], target);

    useEffect(() => {
        const result = data.filter(
            (element) =>
                resolvePath(element, activeField.path)
                    .toString()
                    .toLowerCase()
                    .indexOf(value.toLocaleLowerCase()) > -1
        );
        setFilteredItems(result);
        //eslint-disable-next-line
    }, [value, data]);
    return (
        <Form>
            <Form.Label htmlFor="inlineFormInputGroupUsername2" srOnly>
                Search
            </Form.Label>
            <InputGroup className="mb-2 mr-sm-2">
                <InputGroup.Prepend>
                    <InputGroup.Text className="border-0 bg-info">
                        <i className="fas fa-search text-white"></i>
                    </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                    id="inlineFormInputGroupUsername2"
                    placeholder={`Search ${activeField.title}`}
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                />
                <InputGroup.Append>
                    <Form.Control
                        as="select"
                        className="bg-info text-white"
                        id="inlineFormCustomSelect"
                        custom
                        title={activeField.title}
                        onChange={(e: ChangeEvent<any>) =>
                            setActiveField(searchOptions[e.target.value])
                        }
                    >
                        {searchOptions.map((field, index) => (
                            <option
                                key={field.title}
                                className={`text-white ${
                                    field.title === activeField.title
                                        ? "text-white bg-primary"
                                        : ""
                                }`}
                                value={index}
                            >
                                {field.title}
                            </option>
                        ))}
                    </Form.Control>
                </InputGroup.Append>
            </InputGroup>
        </Form>
    );
};

export default SearchComponent;
