import React from "react";
import { Button, ButtonGroup, Table } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { LinkContainer } from "react-router-bootstrap";
import { Link } from "react-router-dom";
import { deleteProduct } from "../../actions/productActions";
import { Product } from "../../constants/types";
import ImageThumbnailHandler from "../images/ImageThumbnailHandler";

interface Props {
    products: Product[];
}

const ProductAdminTable: React.FC<Props> = ({ products }) => {
    const dispatch = useDispatch();

    const deleteHandler = (id: number) => {
        if (window.confirm("Are you sure?")) {
            dispatch(deleteProduct(id));
        }
    };

    return (
        <Table
            striped
            bordered
            hover
            responsive
            className="table-sm text-center table-vcenter"
        >
            <thead>
                <tr>
                    <th>ID</th>
                    <th>CODE</th>
                    <th>IMAGE</th>
                    <th>NAME</th>
                    <th>PRICE</th>
                    <th>STOCK</th>
                    <th>CATEGORY</th>
                    <th>BRAND</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {products &&
                    products.map((product) => (
                        <tr key={product.id}>
                            <td>{product.id}</td>
                            <td>{product.code}</td>
                            <td>
                                <Link to={`/product/${product.id}`}>
                                    <ImageThumbnailHandler
                                        itemImages={product.images}
                                    />
                                </Link>
                            </td>
                            <td>
                                <Link to={`/product/${product.id}`}>
                                    {product.name}
                                </Link>
                            </td>
                            <td>${product.price}</td>
                            <td>
                                {product.stock === 0 ? (
                                    <i
                                        className="fas fa-times"
                                        style={{
                                            color: "red",
                                        }}
                                    />
                                ) : (
                                    product.stock
                                )}
                            </td>
                            <td>
                                {(typeof product.category === "object" &&
                                    product.category?.name) ||
                                    "None"}
                            </td>
                            <td>{product.brand}</td>
                            <td>
                                <ButtonGroup aria-label="Button Group">
                                    <LinkContainer
                                        to={`/admin/product/${product.id}/edit`}
                                    >
                                        <Button
                                            variant="light"
                                            className="btn-sm"
                                        >
                                            <i className="fas fa-edit" />
                                            <span className="d-block">
                                                EDIT
                                            </span>
                                        </Button>
                                    </LinkContainer>
                                    <Button
                                        variant="danger"
                                        className="btn-sm"
                                        onClick={() =>
                                            deleteHandler(product.id!)
                                        }
                                    >
                                        <i className="fas fa-trash" />
                                        <span className="d-block">DEL</span>
                                    </Button>
                                </ButtonGroup>
                            </td>
                        </tr>
                    ))}
            </tbody>
        </Table>
    );
};

export default ProductAdminTable;
