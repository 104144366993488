import React, { ChangeEvent } from "react";
import { Category } from "../../constants/types";
import FormField from "../FormField";
import CategorySelector from "../category/CategorySelector";

interface Props {
    name: string;
    setName: (name: string) => void;
    price: number;
    setPrice: (price: number) => void;
    code: string;
    setCode: (code: string) => void;
    brand: string;
    setBrand: (brand: string) => void;
    countInStock: number;
    setCountInStock: (countInStock: number) => void;
    categories: Category[];
    setCategory: (category: number) => void;
    categoryId: number;
}

const ProductFieldForm: React.FC<Props> = ({
    name,
    setName,
    price,
    setPrice,
    code,
    setCode,
    brand,
    setBrand,
    countInStock,
    setCountInStock,
    categories,
    setCategory,
    categoryId,
}) => {
    return (
        <>
            <FormField
                controlId="name"
                label="Name"
                value={name}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setName(e.target.value)
                }
            />
            <FormField
                controlId="price"
                type="number"
                label="Price"
                value={price}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setPrice(+e.target.value)
                }
            />
            <FormField
                controlId="code"
                label="Code"
                value={code}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setCode(e.target.value)
                }
            />
            <FormField
                controlId="brand"
                label="Brand"
                value={brand}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setBrand(e.target.value)
                }
            />
            <FormField
                controlId="countInStock"
                type="number"
                label="Count In Stock"
                value={countInStock}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setCountInStock(+e.target.value)
                }
            />
            <CategorySelector
                targetId={categoryId}
                categories={categories}
                setCategory={setCategory}
            />
        </>
    );
};

export default ProductFieldForm;
