import React, { useEffect, useState } from "react";
import Axios from "axios";
import { PayPalButton } from "react-paypal-button-v2";
import { Button, Col, ListGroup, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { match } from "react-router";
import {
    cancelOrder,
    getOrderDetails,
    orderActionReset,
    payOrder,
    resetOrderDetails,
    sentOrder,
} from "../../actions/orderActions";
import GoBack from "../../components/layout/GoBack";
import Loader from "../../components/Loader";
import OrderPaymentMethod from "../../components/order/OrderPaymentMethod";
import OrderProductRow from "../../components/order/OrderProductRow";
import OrderShipping from "../../components/order/OrderShipping";
import OrderSummary from "../../components/order/OrderSummary";
import { OrderActionsState, OrderState } from "../../reducers/orderReducers";
import OrderCanceled from "../../components/order/OrderCanceled";
import { UserAuthState } from "../../reducers/userReducers";
import { RootState } from "../../store";

interface Props {
    match: match<{ id: string }>;
}

declare global {
    interface Window {
        paypal: any;
    }
}

const OrderScreen: React.FC<Props> = ({ match }) => {
    const dispatch = useDispatch();
    const orderId = match.params.id;
    const [sdkReady, setSdkReady] = useState(false);

    const userAuth: UserAuthState = useSelector(
        (state: RootState) => state.userAuth
    );
    const { userInfo } = userAuth;
    const orderDetails: OrderState = useSelector(
        (state: RootState) => state.orderDetails
    );
    const { order, loading, error } = orderDetails;
    const orderActions: OrderActionsState = useSelector(
        (state: RootState) => state.orderActions
    );
    const { loading: loadingAction, success: successAction } = orderActions;

    const addPayPalScript = async () => {
        const { data: clientId } = await Axios.get("/config/paypal");
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.src = `https://www.paypal.com/sdk/js?client-id=${clientId}`;
        script.async = true;
        script.onload = () => {
            setSdkReady(true);
        };
        document.body.appendChild(script);
    };
    const addDecimals = (num: number) => {
        return +(Math.round(num * 100) / 100).toFixed(2);
    };

    if (!loading && !error && order != null && order.orderItems) {
        // Calculate prices
        order.itemsPrice = addDecimals(
            order.orderItems.reduce(
                (acc, item) => acc + item.price! * item.qty!,
                0
            )
        );
    }

    useEffect(() => {
        dispatch(resetOrderDetails());
        dispatch(orderActionReset());
    }, [dispatch, orderId]);

    useEffect(() => {
        if (!order) {
            dispatch(getOrderDetails(orderId));
        } else if (!order?.isPaid) {
            if (!window.paypal) {
                addPayPalScript();
            } else {
                setSdkReady(true);
            }
        }

        // eslint-disable-next-line
    }, [dispatch, order, orderId, successAction]);

    const successPaymentHandler = (paymentResult: any) => {
        dispatch(payOrder(orderId, paymentResult));
    };

    const cancelOrderHandler = () => {
        dispatch(cancelOrder(orderId));
    };

    const sentOrderHandler = () => {
        dispatch(sentOrder(orderId));
    };

    return (
        <>
            <GoBack link="/profile" />
            {loading || loadingAction ? (
                <Loader />
            ) : error ? (
                <h2 className="text-danger">{error}</h2>
            ) : (
                order && (
                    <>
                        <strong className="mx-2 text-secondary">
                            Order {order.id}
                        </strong>
                        <Row>
                            <Col md={8}>
                                <ListGroup variant="flush">
                                    {order.isCanceled && (
                                        <OrderCanceled order={order} />
                                    )}
                                    <OrderShipping
                                        order={order}
                                        email={order.user?.email}
                                        showDeliveryStatus
                                    />
                                    {!order.isCanceled &&
                                        !order.isSent &&
                                        (order.isPaid ||
                                            order.paymentMethod ===
                                                "cashOnDelivery") &&
                                        userInfo?.user?.role?.type ===
                                            "admin" && (
                                            <ListGroup.Item>
                                                {loadingAction && <Loader />}
                                                <Button
                                                    variant="success"
                                                    onClick={sentOrderHandler}
                                                >
                                                    Mark Order Sent
                                                </Button>
                                            </ListGroup.Item>
                                        )}
                                    <OrderPaymentMethod
                                        order={order}
                                        showPaymentStatus
                                    />
                                    <ListGroup.Item>
                                        <h4>Order Items</h4>
                                        {order.orderItems?.length === 0 ? (
                                            <h4>Order is empty</h4>
                                        ) : (
                                            <ListGroup variant="flush">
                                                {order.orderItems?.map(
                                                    (item) => (
                                                        <ListGroup.Item
                                                            key={item.product}
                                                        >
                                                            <OrderProductRow
                                                                item={item}
                                                                addDecimals={
                                                                    addDecimals
                                                                }
                                                            />
                                                        </ListGroup.Item>
                                                    )
                                                )}
                                            </ListGroup>
                                        )}
                                    </ListGroup.Item>
                                </ListGroup>
                            </Col>
                            <Col md={4}>
                                <ListGroup
                                    variant="flush"
                                    className="text-center mt-md-0 mt-3 "
                                >
                                    <OrderSummary order={order} />
                                    {!order.isPaid &&
                                        !order.isCanceled &&
                                        order.paymentMethod === "PayPal" && (
                                            <ListGroup.Item>
                                                {loadingAction && <Loader />}
                                                {!sdkReady ? (
                                                    <Loader />
                                                ) : (
                                                    <PayPalButton
                                                        amount={
                                                            order.totalPrice
                                                        }
                                                        onSuccess={
                                                            successPaymentHandler
                                                        }
                                                    />
                                                )}
                                            </ListGroup.Item>
                                        )}
                                    {!order.isCanceled && !order.isSent && (
                                        <ListGroup.Item>
                                            {loadingAction && <Loader />}
                                            <Button
                                                variant="danger"
                                                onClick={cancelOrderHandler}
                                            >
                                                Cancel Order
                                            </Button>
                                        </ListGroup.Item>
                                    )}
                                </ListGroup>
                            </Col>
                        </Row>
                    </>
                )
            )}
        </>
    );
};

export default OrderScreen;
