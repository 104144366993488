import React from "react";
import { Col, Row } from "react-bootstrap";

const WIP: React.FC = () => {
    return (
        <Row className="border border-warning d-flex align-items-center">
            <Col xs={4}>
                <i
                    className="fas fa-tools text-warning m-0 ml-2"
                    style={{ fontSize: "1.5rem" }}
                />
            </Col>
            <Col
                xs={8}
                className="d-flex flex-column justify-items-center align-items-center"
            >
                <small className="text-warning text-center m-0">WIP</small>
                <small className="text-warning text-center m-0">Feature</small>
            </Col>
        </Row>
    );
};

export default WIP;
