import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { messageListReducer } from "./reducers/messageReducers";
import {
    productDetailsReducer,
    productListReducer,
    productActionsReducer,
} from "./reducers/productReducers";
import { cartReducer } from "./reducers/cartReducers";
import {
    userActionsReducer,
    userAuthReducer,
    userDetailsReducer,
    userListReducer,
} from "./reducers/userReducers";
import {
    orderActionsReducer,
    orderListMyReducer,
    orderListReducer,
    orderReducer,
} from "./reducers/orderReducers";
import { imageActionReducer, imageListReducer } from "./reducers/imageReducers";
import {
    categoryActionReducer,
    categoryDetailsReducer,
    categoryListReducer,
} from "./reducers/categoryReducers";
import {
    reviewListReducer,
    reviewDetailsReducer,
    reviewActionsReducer,
} from "./reducers/reviewReducers";
import { filteringReducer } from "./reducers/filteringReducers";

const reducer = combineReducers({
    messageList: messageListReducer,
    productList: productListReducer,
    productDetails: productDetailsReducer,
    productActions: productActionsReducer,
    reviewDetails: reviewDetailsReducer,
    reviewList: reviewListReducer,
    reviewActions: reviewActionsReducer,
    filtering: filteringReducer,
    categoryAction: categoryActionReducer,
    categoryList: categoryListReducer,
    categoryDetails: categoryDetailsReducer,
    cart: cartReducer,
    userAuth: userAuthReducer,
    userDetails: userDetailsReducer,
    userList: userListReducer,
    userActions: userActionsReducer,
    orderDetails: orderReducer,
    orderList: orderListReducer,
    orderListMy: orderListMyReducer,
    orderActions: orderActionsReducer,
    imageList: imageListReducer,
    imageAction: imageActionReducer,
});

const cartItemsFromStorage = localStorage.getItem("cartItems")
    ? JSON.parse(localStorage.getItem("cartItems")!)
    : [];
const userInfoFromStorage = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo")!)
    : null;
const shippingAddressFromStorage = localStorage.getItem("shippingAddress")
    ? JSON.parse(localStorage.getItem("shippingAddress")!)
    : {};
const paymentMethodFromStorage = localStorage.getItem("paymentMethod")
    ? JSON.parse(localStorage.getItem("paymentMethod")!)
    : "";
const initialState = {
    cart: {
        cartItems: cartItemsFromStorage,
        shippingAddress: shippingAddressFromStorage,
        paymentMethod: paymentMethodFromStorage,
    },
    userAuth: { userInfo: userInfoFromStorage },
};

const middleware = [thunk];

const store = createStore(
    reducer,
    initialState,
    composeWithDevTools(applyMiddleware(...middleware))
);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
