import React from "react";
import AdminOnly from "../../components/admin/AdminOnly";
import CategoryCreate from "../../components/category/CategoryCreate";
import ContentHeader from "../../components/layout/ContentHeader";
import ContentMain from "../../components/layout/ContentMain";
import ContentTitle from "../../components/layout/ContentTitle";
import GoBack from "../../components/layout/GoBack";

interface Props {}

const CategoryCreateScreen: React.FC<Props> = () => {
    return (
        <>
            <AdminOnly />
            <GoBack link="/admin/categorylist" />
            <ContentHeader>
                <ContentTitle text="Add Category"></ContentTitle>
            </ContentHeader>
            <ContentMain>
                <CategoryCreate />
            </ContentMain>
        </>
    );
};

export default CategoryCreateScreen;
