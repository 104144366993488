import React, { useState } from "react";
import { Button, ButtonGroup, Col, Image, Row } from "react-bootstrap";
import { Image as ImageType } from "../../constants/types";
import ImageGallery from "../images/ImageGallery";
import ImageModal from "../images/ImageModal";
import ModalComponent from "../layout/ModalComponent";

interface Props {
    title?: string;
    image: ImageType | null;
    setImage: (image: ImageType | null) => void;
}

const CategoryEditImage: React.FC<Props> = ({
    title = "",
    image,
    setImage,
}) => {
    const [showImageModal, toggleImageModal] = useState(false);
    const [showGalleryModal, toggleGalleryModal] = useState(false);

    const handleRemove = () => {
        setImage(null);
    };

    const handleSelect = (image: ImageType) => {
        setImage(image);
        toggleGalleryModal(false);
    };

    return (
        <>
            <ModalComponent
                toggleModalComponent={toggleGalleryModal}
                showModalComponent={showGalleryModal}
            >
                <ImageGallery selectable handleSelect={handleSelect} />
            </ModalComponent>
            <ImageModal
                title={title}
                imageUrl={
                    image && image?.url ? image.url : "/images/product-icon.png"
                }
                showImageModal={
                    image != null && image?.url != null && showImageModal
                }
                toggleImageModal={toggleImageModal}
            />
            <Row>
                {image ? (
                    <Col key={image?.id} className="text-center">
                        <Image
                            src={
                                image?.formats?.thumbnail?.url != null
                                    ? image.formats.thumbnail.url
                                    : image?.url || "/images/product-icon.png"
                            }
                            alt={image?.name}
                            fluid
                            className={`image-thumbnail border border-info mt-3`}
                        />
                    </Col>
                ) : (
                    <Col className="text-center">
                        <Image
                            src="/images/plus.png"
                            alt="Add Image"
                            fluid
                            className={`image-thumbnail border
                                border-dark
                         mt-3`}
                            onClick={(e) => toggleGalleryModal(true)}
                        />
                    </Col>
                )}
            </Row>

            <ButtonGroup className="mt-3 w-100 mx-auto">
                <Button onClick={(e) => toggleGalleryModal(true)}>
                    Change
                </Button>
                <Button variant="info" onClick={(e) => toggleImageModal(true)}>
                    View
                </Button>
                <Button variant="danger" onClick={handleRemove}>
                    Remove
                </Button>
            </ButtonGroup>
        </>
    );
};

export default CategoryEditImage;
