import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import Loader from "../Loader";
import BackendPageController from "./BackendPageController";
import ItemsPerPageComponent from "./ItemsPerPageComponent";

interface Props {
    count: number;
    loadedItems: number;
    loading?: boolean;
    pageAction: (start: number, limit: number) => void;
    itemsPerPageOptions?: number[];
}

const BackendFilteredComponent: React.FC<Props> = ({
    children,
    count,
    loadedItems,
    loading = false,
    pageAction,
    itemsPerPageOptions = [10, 20, 30, 6, 12, 18, 24, 36],
}) => {
    const [itemsPerPage, setItemsPerPage] = useState(itemsPerPageOptions[0]);
    const [currentPage, setCurrentPage] = useState(1);

    // TODO FIX MOBILE LAYOUT
    return (
        <>
            <Row className="">
                <Col
                    md={4}
                    className="d-flex align-self-center justify-content-center"
                >
                    {loading ? (
                        <Loader variant="small" />
                    ) : (
                        <h6 className="text-info">{`${
                            (currentPage - 1) * itemsPerPage + 1
                        } - ${
                            (currentPage - 1) * itemsPerPage + loadedItems
                        } / ${count}`}</h6>
                    )}
                </Col>
                <Col sm={6} md={4} className="d-flex justify-content-center">
                    <BackendPageController
                        count={count}
                        pageAction={pageAction}
                        itemsPerPage={itemsPerPage}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                    />
                </Col>
                <Col sm={6} md={4}>
                    <ItemsPerPageComponent
                        itemsPerPage={itemsPerPage}
                        setItemsPerPage={setItemsPerPage}
                        itemsPerPageOptions={itemsPerPageOptions}
                    />
                </Col>
            </Row>
            <hr />
            {children}
            <hr />
            <Row className="">
                <Col className="d-flex justify-content-center">
                    <BackendPageController
                        count={count}
                        pageAction={pageAction}
                        itemsPerPage={itemsPerPage}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                    />
                </Col>
            </Row>
        </>
    );
};

export default BackendFilteredComponent;
