import React, { useState } from "react";
import { Col, Image, Row } from "react-bootstrap";
import { Image as ImageType } from "../../constants/types";
import ImageModal from "./ImageModal";

interface Props {
    title?: string;
    images?: ImageType[] | null;
}

const ProductImages: React.FC<Props> = ({ title = "", images }) => {
    const [activeImage, setActiveImage] = useState(0);
    const [showImageModal, toggleImageModal] = useState(false);
    return (
        <>
            <ImageModal
                title={title}
                imageUrl={
                    images != null && images[activeImage]?.url
                        ? images[activeImage].url
                        : "/images/product-icon.png"
                }
                showImageModal={
                    images != null &&
                    images[activeImage]?.url != null &&
                    showImageModal
                }
                toggleImageModal={toggleImageModal}
            />
            <Image
                src={
                    images != null && images[activeImage]?.url
                        ? images[activeImage].url
                        : "/images/product-icon.png"
                }
                alt={
                    images != null && images[activeImage]?.name
                        ? images[activeImage].name
                        : "Product Image"
                }
                fluid
                onClick={(e) => toggleImageModal(true)}
            />
            <Row>
                {images?.map((image, index) => (
                    <Col key={image.id} md={2} sm={4} xs={6}>
                        <Image
                            src={
                                image?.formats?.thumbnail?.url != null
                                    ? image.formats.thumbnail.url
                                    : image.url
                            }
                            alt={image.name}
                            fluid
                            className={`image-thumbnail border ${
                                index === activeImage
                                    ? "border-info"
                                    : "border-dark faded"
                            } mt-3`}
                            onClick={(e) => setActiveImage(index)}
                        />
                    </Col>
                ))}
            </Row>
        </>
    );
};

export default ProductImages;
