import { GeneralStateIF, GenericAction, User } from "../constants/types";
import {
    USER_LOGIN_FAIL,
    USER_LOGIN_REQUEST,
    USER_LOGIN_SUCCESS,
    USER_LOGOUT,
    USER_REGISTER_FAIL,
    USER_REGISTER_REQUEST,
    USER_REGISTER_SUCCESS,
    USER_DETAILS_REQUEST,
    USER_DETAILS_SUCCESS,
    USER_DETAILS_FAIL,
    USER_DETAILS_RESET,
    USER_LIST_REQUEST,
    USER_LIST_SUCCESS,
    USER_LIST_FAIL,
    USER_LIST_RESET,
    USER_UPDATE_ME_REQUEST,
    USER_UPDATE_ME_SUCCESS,
    USER_UPDATE_ME_FAIL,
    USER_ACTION_RESET,
    USER_GET_FAVORITES_REQUEST,
    USER_GET_FAVORITES_SUCCESS,
    USER_GET_FAVORITES_FAIL,
    USER_ADD_FAVORITE_FAIL,
    USER_ADD_FAVORITE_REQUEST,
    USER_ADD_FAVORITE_SUCCESS,
    USER_REMOVE_FAVORITE_SUCCESS,
    USER_REMOVE_FAVORITE_REQUEST,
    USER_REMOVE_FAVORITE_FAIL,
    USER_BLOCK_REQUEST,
    USER_BLOCK_SUCCESS,
    USER_BLOCK_FAIL,
} from "../constants/userConstants";

const userActionsInitialState = {};

export interface UserActionsState extends GeneralStateIF {
    actionType?: string;
}
export const userActionsReducer = (
    state: UserActionsState = userActionsInitialState,
    action: GenericAction
): UserActionsState => {
    switch (action.type) {
        case USER_UPDATE_ME_REQUEST:
            return { loading: true, actionType: USER_UPDATE_ME_REQUEST };
        case USER_GET_FAVORITES_REQUEST:
            return { loading: true, actionType: USER_GET_FAVORITES_REQUEST };
        case USER_ADD_FAVORITE_REQUEST:
            return { loading: true, actionType: USER_ADD_FAVORITE_REQUEST };
        case USER_REMOVE_FAVORITE_REQUEST:
            return { loading: true, actionType: USER_REMOVE_FAVORITE_REQUEST };
        case USER_BLOCK_REQUEST:
            return { loading: true, actionType: USER_BLOCK_REQUEST };
        case USER_UPDATE_ME_SUCCESS:
        case USER_GET_FAVORITES_SUCCESS:
        case USER_ADD_FAVORITE_SUCCESS:
        case USER_REMOVE_FAVORITE_SUCCESS:
        case USER_BLOCK_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case USER_UPDATE_ME_FAIL:
        case USER_GET_FAVORITES_FAIL:
        case USER_ADD_FAVORITE_FAIL:
        case USER_REMOVE_FAVORITE_FAIL:
        case USER_BLOCK_FAIL:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case USER_ACTION_RESET:
            return userActionsInitialState;
        default:
            return state;
    }
};

const userAuthInitialState = {};

export interface UserAuthState extends GeneralStateIF {
    userInfo?: {
        jwt: string;
        user: User;
    };
}

export const userAuthReducer = (
    state: UserAuthState = userAuthInitialState,
    action: GenericAction
): UserAuthState => {
    switch (action.type) {
        case USER_LOGIN_REQUEST:
        case USER_REGISTER_REQUEST:
            return { loading: true };
        case USER_LOGIN_SUCCESS:
        case USER_REGISTER_SUCCESS:
            return {
                loading: false,
                userInfo: action.payload,
            };
        case USER_LOGIN_FAIL:
        case USER_REGISTER_FAIL:
            return { loading: false, error: action.payload };
        case USER_LOGOUT:
            return userAuthInitialState;
        default:
            return state;
    }
};

const userDetailsInitialState = { user: {} };

export interface UserDetailsState extends GeneralStateIF {
    user?: User;
}

export const userDetailsReducer = (
    state: UserDetailsState = userDetailsInitialState,
    action: GenericAction
): UserDetailsState => {
    switch (action.type) {
        case USER_DETAILS_REQUEST:
            return { ...state, loading: true };
        case USER_DETAILS_SUCCESS:
        case USER_UPDATE_ME_SUCCESS:
        case USER_ADD_FAVORITE_SUCCESS:
        case USER_REMOVE_FAVORITE_SUCCESS:
        case USER_BLOCK_SUCCESS:
            return { loading: false, user: action.payload };
        case USER_GET_FAVORITES_SUCCESS:
            return {
                loading: false,
                user: { ...state.user, favorites: action.payload },
            };
        case USER_DETAILS_FAIL:
            return { loading: false, error: action.payload };
        case USER_DETAILS_RESET:
            return userDetailsInitialState;
        default:
            return state;
    }
};

const userListInitialState = { users: [] };

export interface UserListState extends GeneralStateIF {
    users?: User[];
}

export const userListReducer = (
    state: UserListState = userListInitialState,
    action: GenericAction
): UserListState => {
    switch (action.type) {
        case USER_LIST_REQUEST:
            return { ...state, loading: true };
        case USER_LIST_SUCCESS:
            return { loading: false, users: action.payload };
        case USER_LIST_FAIL:
            return { loading: false, error: action.payload };
        case USER_LIST_RESET:
            return userListInitialState;
        default:
            return state;
    }
};
