import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Form } from "react-bootstrap";
import { uploadImage } from "../../actions/imageActions";

interface Props {
    toggleModalComponent: (v: boolean) => void;
}

const ImageUpload: React.FC<Props> = ({ toggleModalComponent }) => {
    const dispatch = useDispatch();
    const [file, setFile] =
        useState<{
            name: string;
            size: number;
            type: string;
        } | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [draggingOver, setDraggingOver] = useState(false);

    const types = ["image/png", "image/jpeg"];
    let dragCounter = 0;

    const changeFileHandler = (selectedFile: File) => {
        if (selectedFile && types.includes(selectedFile.type)) {
            setFile(selectedFile);
            setError(null);
        } else {
            setFile(null);
            setError("Please select an image file (png or jpeg)");
        }
    };

    const submitHandler = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        dispatch(uploadImage(file));
        toggleModalComponent(false);
    };
    const dragEnterHandler = (e: React.DragEvent<HTMLDivElement>) => {
        e.stopPropagation();
        e.preventDefault();
        dragCounter++;
        if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
            setDraggingOver(true);
        }
    };
    const dragLeaveHandler = (e: React.DragEvent<HTMLDivElement>) => {
        e.stopPropagation();
        e.preventDefault();
        dragCounter--;
        if (dragCounter < 0) {
            setDraggingOver(false);
        }
    };

    const dragOverHandler = (e: React.DragEvent<HTMLDivElement>) => {
        e.stopPropagation();
        e.preventDefault();
        if (!draggingOver) {
            setDraggingOver(true);
        }
    };

    const dropHandler = (e: React.DragEvent<HTMLDivElement>) => {
        e.stopPropagation();
        e.preventDefault();
        setDraggingOver(false);
        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            changeFileHandler(e.dataTransfer.files[0]);
            e.dataTransfer.clearData();
            dragCounter = 0;
        }
    };
    return (
        <Form
            className="d-flex flex-column justify-content-between py-5"
            style={{ minHeight: "400px" }}
            onSubmit={(e: React.FormEvent<HTMLFormElement>) => submitHandler(e)}
        >
            <Form.File id="formcheck-api-custom" custom>
                <Form.File.Input
                    isValid={file !== null}
                    isInvalid={error !== null}
                    accept=".png, .jpeg, .jpg, image/*"
                    onChange={(e: any) => changeFileHandler(e.target.files[0])}
                />
                <Form.File.Label
                    data-browse="Browse"
                    style={{ textOverflow: "ellipsis" }}
                >
                    {file?.name || "Upload an image"}
                </Form.File.Label>
                <Form.Control.Feedback type="valid">
                    Accepted
                </Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">
                    {error}
                </Form.Control.Feedback>
            </Form.File>
            <h2 className="text-center text-secondary  m-3">OR</h2>
            <div
                className={`border ${
                    draggingOver
                        ? "border-success text-success border-dashed"
                        : "border-secondary text-secondary"
                } border-3 d-flex flex-column align-items-center justify-content-center mb-3 `}
                style={{
                    width: "300px",
                    height: "300px",
                    backgroundColor: "transparent",
                }}
                onDragEnter={(e: React.DragEvent<HTMLDivElement>) =>
                    dragEnterHandler(e)
                }
                onDragLeave={(e) => dragLeaveHandler(e)}
                onDragOver={dragOverHandler}
                onDrop={(e) => dropHandler(e)}
            >
                {draggingOver ? (
                    <>
                        <h6>Drop</h6>
                        <i
                            className="fas fa-compress-arrows-alt"
                            style={{ fontSize: "75px" }}
                        />
                    </>
                ) : (
                    <>
                        <h6 className="">Drag Images Here</h6>
                        <i
                            className="fas fa-file-image mt-2"
                            style={{ fontSize: "75px" }}
                        />
                    </>
                )}
            </div>

            <Button disabled={file == null} variant="primary" type="submit">
                Upload
            </Button>
        </Form>
    );
};

export default ImageUpload;
