import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table } from "react-bootstrap";
import { UserAuthState } from "../../reducers/userReducers";
import { blockUser, unblockUser } from "../../actions/userActions";
import FilteredComponent from "../layout/FilteredComponent";
import UserListItem from "./UserListItem";
import { RootState } from "../../store";
import { User } from "../../constants/types";

interface Props {
    users: User[];
}

const UserList: React.FC<Props> = ({ users }) => {
    const dispatch = useDispatch();
    const [listItems, setListItems] = useState(users);
    const userAuth: UserAuthState = useSelector(
        (state: RootState) => state.userAuth
    );
    const { userInfo } = userAuth;

    const blockHandler = (userId: number) => {
        dispatch(blockUser(userId));
    };

    const unblockHandler = (userId: number) => {
        dispatch(unblockUser(userId));
    };

    return (
        <FilteredComponent
            data={users}
            setListItems={setListItems}
            searchOptions={[
                { path: "name", title: "Name" },
                { path: "username", title: "Username" },
                { path: "id", title: "ID" },
                { path: "email", title: "Email" },
            ]}
        >
            <Table
                striped
                bordered
                hover
                responsive
                className="table-sm text-center mt-3 table-vcenter"
            >
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>NAME</th>
                        <th>USERNAME</th>
                        <th>EMAIL</th>
                        <th>ACCESS</th>
                        <th>ORDERS</th>
                        <th>REVIEWS</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {listItems.map((user) => (
                        <UserListItem
                            key={user.id}
                            user={user}
                            blockHandler={blockHandler}
                            unblockHandler={unblockHandler}
                            userInfo={userInfo!}
                        />
                    ))}
                </tbody>
            </Table>
        </FilteredComponent>
    );
};

export default UserList;
