import React, { useState } from "react";
import { Button, ButtonGroup, Col, Image, Row } from "react-bootstrap";
import { Image as ImageType } from "../../constants/types";
import ImageModal from "./ImageModal";
import ModalComponent from "../layout/ModalComponent";
import ImageGallery from "./ImageGallery";

interface Props {
    title?: string;
    images: ImageType[] | null;
    setImages: (images: ImageType[] | null) => void;
}

const ProductEditImages: React.FC<Props> = ({
    title = "",
    images,
    setImages,
}) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [showImageModal, toggleImageModal] = useState(false);
    const [showGalleryModal, toggleGalleryModal] = useState(false);

    const handleAdd = () => {
        setActiveIndex(images?.length || 0);
        toggleGalleryModal(true);
    };

    const handleRemove = () => {
        let editedArr = images;
        if (editedArr) {
            editedArr.splice(activeIndex, 1);
            setImages([...editedArr]);
            setActiveIndex(activeIndex > 0 ? activeIndex - 1 : 0);
        }
    };

    const handleSelect = (image: ImageType) => {
        let editedArr = images || [];
        if (editedArr) {
            editedArr.splice(activeIndex, 1, image);
            setImages([...editedArr]);
            toggleGalleryModal(false);
        }
    };

    const handleMoveLeft = () => {
        if (activeIndex > 0) {
            let editedArr = images;
            if (editedArr && images) {
                editedArr.splice(
                    activeIndex - 1,
                    2,
                    images[activeIndex],
                    images[activeIndex - 1]
                );
                setImages([...editedArr]);
                setActiveIndex(activeIndex - 1);
            }
        }
    };

    const handleMoveRight = () => {
        if (images && activeIndex < images?.length) {
            let editedArr = images;
            if (editedArr) {
                editedArr.splice(
                    activeIndex,
                    2,
                    images[activeIndex + 1],
                    images[activeIndex]
                );
                setImages([...editedArr]);
                setActiveIndex(activeIndex + 1);
            }
        }
    };

    return (
        <>
            <ModalComponent
                toggleModalComponent={toggleGalleryModal}
                showModalComponent={showGalleryModal}
            >
                <ImageGallery selectable handleSelect={handleSelect} />
            </ModalComponent>
            <ImageModal
                title={title}
                imageUrl={
                    images != null && images[activeIndex]?.url
                        ? images[activeIndex].url
                        : "/images/product-icon.png"
                }
                showImageModal={
                    images != null &&
                    images[activeIndex]?.url != null &&
                    showImageModal
                }
                toggleImageModal={toggleImageModal}
            />
            <Row>
                {images?.map((image, index) => (
                    <Col key={image?.id} md={4} sm={6} xs={6}>
                        <Image
                            src={
                                image?.formats?.thumbnail?.url != null
                                    ? image.formats.thumbnail.url
                                    : image?.url || "/images/product-icon.png"
                            }
                            alt={image?.name}
                            fluid
                            className={`image-thumbnail border ${
                                index === activeIndex
                                    ? "border-info"
                                    : "border-dark faded"
                            } mt-3`}
                            onClick={(e) => setActiveIndex(index)}
                        />
                    </Col>
                ))}
                {(images == null || images.length < 6) && (
                    <Col md={4} sm={6} xs={6}>
                        <Image
                            src="/images/plus.png"
                            alt="Add Image"
                            fluid
                            className={`image-thumbnail border
                                border-dark
                         mt-3`}
                            onClick={(e) => handleAdd()}
                        />
                    </Col>
                )}
            </Row>

            <ButtonGroup className="mt-3 w-100 mx-auto">
                <Button
                    variant="secondary"
                    disabled={activeIndex === 0}
                    onClick={handleMoveLeft}
                >
                    <i className="fas fa-chevron-left" />
                </Button>
                <Button onClick={(e) => toggleGalleryModal(true)}>
                    Change
                </Button>
                <Button variant="info" onClick={(e) => toggleImageModal(true)}>
                    View
                </Button>
                <Button variant="danger" onClick={handleRemove}>
                    Remove
                </Button>
                <Button
                    variant="secondary"
                    disabled={
                        activeIndex + 1 === images?.length || images == null
                    }
                    onClick={handleMoveRight}
                >
                    <i className="fas fa-chevron-right" />
                </Button>
            </ButtonGroup>
        </>
    );
};

export default ProductEditImages;
