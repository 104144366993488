import React from "react";
import { Col } from "react-bootstrap";

interface Props {
    showSideBar?: boolean;
}

const ContentSidebar: React.FC<Props> = ({ showSideBar = true, children }) => {
    return (
        <>
            {showSideBar && (
                <Col md={4} lg={3}>
                    {children}
                </Col>
            )}
        </>
    );
};

export default ContentSidebar;
