import React from "react";
import { Link } from "react-router-dom";

const GoBack = ({ link = "/" }) => {
    return (
        <Link to={link}>
            <span className="btn btn-dark my-3">
                {link === "/" ? "Home" : "Go Back"}
            </span>
        </Link>
    );
};

export default GoBack;
