import React, { Fragment, useEffect } from "react";
import { Pagination } from "react-bootstrap";

interface Props {
    count: number;
    pageAction: (start: number, limit: number) => void;
    itemsPerPage: number;
    currentPage: number;
    setCurrentPage: (page: number) => void;
}

const BackendPageController: React.FC<Props> = ({
    count,
    itemsPerPage,
    pageAction,
    currentPage,
    setCurrentPage,
}) => {
    const hasPages = count > itemsPerPage;
    const totalPages = Math.ceil(count / itemsPerPage);
    const goPreviousPage = () => {
        setCurrentPage(currentPage - 1);
    };
    const goNextPage = () => {
        setCurrentPage(currentPage + 1);
    };

    const getPaginationGroup = () => {
        return new Array(totalPages).fill(1).map((_, idx) => idx + 1);
    };

    const PaginationComponent = () => (
        <Pagination className="mb-0">
            <Pagination.Prev
                onClick={goPreviousPage}
                disabled={currentPage === 1}
            />
            <Pagination.Item
                onClick={(e) => setCurrentPage(1)}
                active={currentPage === 1}
            >
                {1}
            </Pagination.Item>
            {currentPage > 1 + 3 && <Pagination.Ellipsis />}
            {getPaginationGroup().map((pageNumber) => (
                <Fragment key={pageNumber}>
                    {pageNumber > 1 &&
                        pageNumber < totalPages &&
                        pageNumber < currentPage + 3 &&
                        pageNumber > currentPage - 3 && (
                            <Pagination.Item
                                onClick={(e) => setCurrentPage(pageNumber)}
                                active={currentPage === pageNumber}
                            >
                                {pageNumber}
                            </Pagination.Item>
                        )}
                </Fragment>
            ))}
            {currentPage < totalPages - 3 && <Pagination.Ellipsis />}
            <Pagination.Item
                onClick={(e) => setCurrentPage(totalPages)}
                active={currentPage === totalPages}
            >
                {totalPages}
            </Pagination.Item>
            <Pagination.Next
                onClick={goNextPage}
                disabled={currentPage === totalPages}
            />
        </Pagination>
    );

    useEffect(() => {
        setCurrentPage(1);
        //eslint-disable-next-line
    }, [itemsPerPage]);

    useEffect(() => {
        pageAction((currentPage - 1) * itemsPerPage, itemsPerPage);
        //eslint-disable-next-line
    }, [currentPage, itemsPerPage]);

    return <>{hasPages && <PaginationComponent />}</>;
};

export default BackendPageController;
