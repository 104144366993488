import React from "react";
import { Form } from "react-bootstrap";

interface Props {
    comment: string;
    rating: number;
    setComment: (comment: string) => void;
    setRating: (rating: number) => void;
}

const ReviewFields: React.FC<Props> = ({
    comment,
    rating,
    setComment,
    setRating,
}) => {
    return (
        <>
            <Form.Group controlId={`review-comment`}>
                <Form.Label>Review Comment</Form.Label>
                <Form.Control
                    as="textarea"
                    placeholder="Add a comment"
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    rows={3}
                    maxLength={250}
                ></Form.Control>
                <Form.Text className="text-muted">
                    {comment.length} / 250
                </Form.Text>
            </Form.Group>
            <Form.Group controlId="review-rating">
                <Form.Label>Rating</Form.Label>
                <h3 className="text-info">
                    <span onClick={(e) => setRating(1)}>
                        <i
                            className={
                                rating >= 1
                                    ? "fas fa-star"
                                    : rating >= 0.5
                                    ? "fas fa-star-half-alt"
                                    : "far fa-star"
                            }
                        />
                    </span>
                    <span onClick={(e) => setRating(2)}>
                        <i
                            className={
                                rating >= 2
                                    ? "fas fa-star"
                                    : rating >= 1.5
                                    ? "fas fa-star-half-alt"
                                    : "far fa-star"
                            }
                        />
                    </span>
                    <span onClick={(e) => setRating(3)}>
                        <i
                            className={
                                rating >= 3
                                    ? "fas fa-star"
                                    : rating >= 2.5
                                    ? "fas fa-star-half-alt"
                                    : "far fa-star"
                            }
                        />
                    </span>
                    <span onClick={(e) => setRating(4)}>
                        <i
                            className={
                                rating >= 4
                                    ? "fas fa-star"
                                    : rating >= 3.5
                                    ? "fas fa-star-half-alt"
                                    : "far fa-star"
                            }
                        />
                    </span>
                    <span onClick={(e) => setRating(5)}>
                        <i
                            className={
                                rating >= 5
                                    ? "fas fa-star"
                                    : rating >= 4.5
                                    ? "fas fa-star-half-alt"
                                    : "far fa-star"
                            }
                        />
                    </span>
                </h3>
            </Form.Group>
        </>
    );
};

export default ReviewFields;
