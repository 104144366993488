import React, { useState, useEffect } from "react";
import { Card, Form, ListGroup } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { changeFilters } from "../../actions/filteringActions";
import { defaultFilters } from "../../constants/filteringConstants";
import { CategoryListState } from "../../reducers/categoryReducers";
import { FilteringState } from "../../reducers/filteringReducers";
import { RootState } from "../../store";

interface Props {
    defaultItemsPerPage?: number;
    perPageOptions?: number[];
}

const ProductFilteringSort: React.FC<Props> = ({
    perPageOptions = [12, 18, 24, 36, 48],
    defaultItemsPerPage = perPageOptions[2],
}) => {
    const dispatch = useDispatch();
    const filtering: FilteringState = useSelector(
        (state: RootState) => state.filtering
    );
    const { filters } = filtering;
    const categoryList: CategoryListState = useSelector(
        (state: RootState) => state.categoryList
    );
    const { categories } = categoryList;
    const [sortBy, setSortBy] = useState(
        filters?._sort || defaultFilters._sort
    );
    const [perPage, setPerPage] = useState(
        filters?._limit || defaultItemsPerPage
    );

    useEffect(() => {
        if (filters?._sort !== sortBy) {
            setSortBy(filters?._sort);
        }
        if (filters?._limit !== perPage) {
            setPerPage(filters?._limit);
        }
        //eslint-disable-next-line
    }, [categories]);

    useEffect(() => {
        dispatch(
            changeFilters({
                ...filters,
                _sort: sortBy,
                _limit: perPage,
            })
        );
        // eslint-disable-next-line
    }, [sortBy, perPage]);
    return (
        <>
            <Card.Title className="text-primary">Sorting</Card.Title>
            <ListGroup variant="flush">
                <Form.Group>
                    <Form.Control
                        as="select"
                        className="mr-sm-2"
                        id="category"
                        custom
                        value={sortBy}
                        onChange={(e) => setSortBy(e.target.value)}
                    >
                        <option value="created_at:DESC">Most Recent</option>
                        <option value="created_at:ASC">Oldest</option>
                        <option value="price:ASC">Price: Ascending</option>
                        <option value="price:DESC">Price: Descending</option>
                    </Form.Control>
                </Form.Group>
                <Form.Group>
                    <Form.Control
                        as="select"
                        className="mr-sm-2"
                        id="category"
                        custom
                        value={perPage}
                        onChange={(e) => setPerPage(+e.target.value)}
                    >
                        {perPageOptions.map((option) => (
                            <option key={option} value={option}>
                                {option}
                            </option>
                        ))}
                    </Form.Control>
                </Form.Group>
            </ListGroup>
        </>
    );
};

export default ProductFilteringSort;
