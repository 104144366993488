import React from "react";
import { Col, Row } from "react-bootstrap";

interface Props {}

const ContentFooter: React.FC<Props> = ({ children }) => {
    return (
        <Row className="d-flex align-items-center mb-3">
            <Col md={4} lg={3}></Col>
            {children}
        </Row>
    );
};

export default ContentFooter;
