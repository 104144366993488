import {
    IMAGE_ACTION_RESET,
    IMAGE_COUNT_FAIL,
    IMAGE_COUNT_REQUEST,
    IMAGE_COUNT_RESET,
    IMAGE_COUNT_SUCCESS,
    IMAGE_DELETE_FAIL,
    IMAGE_DELETE_REQUEST,
    IMAGE_DELETE_SUCCESS,
    IMAGE_LIST_FAIL,
    IMAGE_LIST_REQUEST,
    IMAGE_LIST_SUCCESS,
    IMAGE_UPLOAD_FAIL,
    IMAGE_UPLOAD_REQUEST,
    IMAGE_UPLOAD_SUCCESS,
} from "../constants/imageConstants";
import { GeneralStateIF, GenericAction, Image } from "../constants/types";

const imageActionInitialState = {};

export interface ImageActionState extends GeneralStateIF {
    actionType?: string;
}

export const imageActionReducer = (
    state: ImageActionState = imageActionInitialState,
    action: GenericAction
): ImageActionState => {
    switch (action.type) {
        case IMAGE_UPLOAD_REQUEST:
            return { loading: true, actionType: IMAGE_UPLOAD_REQUEST };
        case IMAGE_DELETE_REQUEST:
            return { loading: true, actionType: IMAGE_DELETE_REQUEST };
        case IMAGE_UPLOAD_SUCCESS:
        case IMAGE_DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case IMAGE_UPLOAD_FAIL:
        case IMAGE_DELETE_FAIL:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case IMAGE_ACTION_RESET:
            return imageActionInitialState;
        default:
            return state;
    }
};

const imageListInitialState = {
    images: [],
};

export interface ImageListState extends GeneralStateIF {
    count?: number | null;
    images?: Image[];
}

export const imageListReducer = (
    state: ImageListState = imageListInitialState,
    action: GenericAction
): ImageListState => {
    switch (action.type) {
        case IMAGE_LIST_REQUEST:
        case IMAGE_COUNT_REQUEST:
            return { loading: true, ...state };
        case IMAGE_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                images: action.payload,
            };
        case IMAGE_COUNT_SUCCESS:
            return {
                ...state,
                loading: false,
                count: action.payload,
            };
        case IMAGE_COUNT_RESET:
            return {
                ...state,
                count: null,
            };
        case IMAGE_LIST_FAIL:
        case IMAGE_COUNT_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};
