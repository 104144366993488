import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Form } from "react-bootstrap";
import { RouteComponentProps } from "react-router";
import FormContainer from "../../components/FormContainer";
import CheckoutSteps from "../../components/order/CheckoutSteps";
import GoBack from "../../components/layout/GoBack";
import { savePaymentMethod } from "../../actions/cartActions";
import { CartState } from "../../reducers/cartReducers";
import { RootState } from "../../store";

interface Props extends RouteComponentProps {}

const PaymentScreen: React.FC<Props> = ({ history }) => {
    const cart: CartState = useSelector((state: RootState) => state.cart);
    const { shippingAddress } = cart;

    if (!shippingAddress) {
        history.push("/shipping");
    }

    const dispatch = useDispatch();
    const [paymentMethod, setPaymentMethod] = useState("PayPal");

    const submitHandler = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        dispatch(savePaymentMethod(paymentMethod));
        history.push("/placeorder");
    };
    return (
        <>
            <GoBack />
            <CheckoutSteps step1 step2 step3 />
            <FormContainer>
                <h1>Payment Method</h1>
                <Form onSubmit={submitHandler}>
                    <Form.Group>
                        <Form.Label as="legend">Select Method</Form.Label>

                        <Col>
                            <Form.Check
                                type="radio"
                                label="PayPal or Credit Card"
                                id="PayPal"
                                name="paymentMethod"
                                value="PayPal"
                                checked
                                onChange={(e) =>
                                    setPaymentMethod(
                                        (e.target as HTMLInputElement).value
                                    )
                                }
                            ></Form.Check>
                            <Form.Check
                                type="radio"
                                label="Cash On Delivery"
                                id="cashOnDelivery"
                                name="paymentMethod"
                                value="cashOnDelivery"
                                onChange={(e) =>
                                    setPaymentMethod(
                                        (e.target as HTMLInputElement).value
                                    )
                                }
                            ></Form.Check>
                            <Form.Check
                                type="radio"
                                label="Stripe"
                                id="Stripe"
                                name="paymentMethod"
                                value="Stripe"
                                disabled
                                onChange={(e) =>
                                    setPaymentMethod(
                                        (e.target as HTMLInputElement).value
                                    )
                                }
                            ></Form.Check>
                        </Col>
                    </Form.Group>
                    <Button type="submit" variant="primary">
                        Continue
                    </Button>
                </Form>
            </FormContainer>
        </>
    );
};

export default PaymentScreen;
