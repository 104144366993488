import React, { useState, useEffect, ChangeEvent } from "react";
import { Form, Row, Col } from "react-bootstrap";
import { SpecificationFields } from "../../constants/types";
import FormField from "../FormField";

interface Props {
    field: SpecificationFields;
    specification: any;
    setSpecification: (specification: Object) => void;
}

const ProductSpecificationEditFieldRow: React.FC<Props> = ({
    field,
    specification,
    setSpecification,
}) => {
    const [value, setValue] = useState<string | number>(
        specification[field.fieldName] || ""
    );
    useEffect(() => {
        setSpecification({ ...specification, [field.fieldName]: value });
        //eslint-disable-next-line
    }, [value]);
    return (
        <div key={field.fieldName}>
            {field.fieldType === "option" ? (
                <Form.Group as={Row}>
                    <Form.Label column sm={2}>
                        {field.fieldName}
                    </Form.Label>
                    <Col sm={10}>
                        <Form.Control
                            as="select"
                            custom
                            value={value}
                            onChange={(e) => setValue(e.target.value)}
                        >
                            <option value={""}></option>
                            {field.fieldOptions?.split(",").map((el) => (
                                <option key={el} value={el}>
                                    {el}
                                </option>
                            ))}
                        </Form.Control>
                    </Col>
                </Form.Group>
            ) : (
                <FormField
                    horizontal
                    controlId={`${field.fieldName}-control`}
                    label={field.fieldName}
                    value={value}
                    type={field.fieldType === "number" ? "number" : "text"}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setValue(e.target.value)
                    }
                />
            )}
        </div>
    );
};

export default ProductSpecificationEditFieldRow;
