import React, { useState } from "react";
import { Col, Nav, Row } from "react-bootstrap";
import { Category } from "../../constants/types";
import Stonks from "./Stonks";
import SubcategoryMenu from "./SubcategoryMenu";

interface Props {
    categories: Category[];
    showCategoryMenu: boolean;
    toggleCategoryMenu: (val: boolean) => void;
}

const CategoryMenu: React.FC<Props> = ({
    categories,
    showCategoryMenu,
    toggleCategoryMenu,
}) => {
    const [activeCategory, setActiveCategory] = useState<Category | null>(null);

    return (
        <div className="d-none d-md-block">
            <Row
                className="p-2"
                onMouseLeave={(
                    e: React.MouseEvent<HTMLDivElement, MouseEvent>
                ) => setActiveCategory(null)}
            >
                <Col md={4} className="">
                    {showCategoryMenu && (
                        <>
                            {categories.map(
                                (category) =>
                                    category.parent === null && (
                                        <Nav.Link
                                            key={category.id}
                                            onMouseEnter={(
                                                e: React.MouseEvent<
                                                    HTMLAnchorElement,
                                                    MouseEvent
                                                >
                                            ) => setActiveCategory(category)}
                                        >
                                            <h5>{category.name}</h5>
                                        </Nav.Link>
                                    )
                            )}
                        </>
                    )}
                </Col>
                {showCategoryMenu && (
                    <Col md={8}>
                        {activeCategory !== null ? (
                            <SubcategoryMenu activeCategory={activeCategory} />
                        ) : (
                            <Stonks />
                        )}
                    </Col>
                )}
            </Row>
        </div>
    );
};

export default CategoryMenu;
