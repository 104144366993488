import React from "react";
import { Col, ListGroup, Row, Button } from "react-bootstrap";
import { Order } from "../../constants/types";

interface Props {
    order: Order;
    showPlaceOrderButton?: boolean;
    placeOrderHandler?: (e: any) => void;
}

const OrderSummary: React.FC<Props> = ({
    order,
    showPlaceOrderButton = false,
    placeOrderHandler,
}) => {
    return (
        <>
            <ListGroup.Item>
                <h4>Order Summary</h4>
            </ListGroup.Item>
            <ListGroup.Item>
                <Row>
                    <Col>Items</Col>
                    <Col>${order.itemsPrice}</Col>
                </Row>
            </ListGroup.Item>
            <ListGroup.Item>
                <Row>
                    <Col>Shipping</Col>
                    <Col>${order.shippingPrice}</Col>
                </Row>
            </ListGroup.Item>
            <ListGroup.Item>
                <Row>
                    <Col>Tax</Col>
                    <Col>${order.taxPrice}</Col>
                </Row>
            </ListGroup.Item>
            <ListGroup.Item>
                <Row>
                    <Col>Total</Col>
                    <Col>${order.totalPrice}</Col>
                </Row>
            </ListGroup.Item>
            {showPlaceOrderButton && (
                <ListGroup.Item>
                    <Button
                        type="button"
                        className="btn-block"
                        onClick={placeOrderHandler}
                    >
                        Place Order
                    </Button>
                </ListGroup.Item>
            )}
        </>
    );
};

export default OrderSummary;
