import React, { useEffect, useState } from "react";
import { Card, Form, ListGroup, Nav } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { changeFilters } from "../../actions/filteringActions";
import { Category } from "../../constants/types";
import { CategoryListState } from "../../reducers/categoryReducers";
import { FilteringState } from "../../reducers/filteringReducers";
import { RootState } from "../../store";
import Loader from "../Loader";

interface Props {}

const ProductFilteringCategoryList: React.FC<Props> = () => {
    const dispatch = useDispatch();

    const filtering: FilteringState = useSelector(
        (state: RootState) => state.filtering
    );
    const { filters } = filtering;

    const categoryList: CategoryListState = useSelector(
        (state: RootState) => state.categoryList
    );
    const { loading, categories } = categoryList;

    const [selectedParent, setSelectedParent] = useState<number | null>(null);
    const [activeCategory, setActiveCategory] = useState<number | null>(null);

    useEffect(() => {
        if (activeCategory !== Number(filters.category)) {
            let initialCategory = categories?.filter(
                (category) => category.id === Number(filters?.category)
            )[0];
            setActiveCategory(initialCategory?.id || null);
            if (initialCategory?.parent) {
                setSelectedParent(initialCategory?.parent?.id || null);
            } else if (initialCategory?.children) {
                setSelectedParent(initialCategory?.id || null);
            }
            if (filters.category === "") {
                setSelectedParent(null);
            }
        }
        // eslint-disable-next-line
    }, [categories, filters]);

    const selectHandler = (categoryId: number) => {
        if (categoryId === activeCategory) {
            dispatch(changeFilters({ ...filters, category: null }));
            setSelectedParent(null);
        } else {
            dispatch(changeFilters({ ...filters, category: categoryId }));
        }
    };

    return (
        <div>
            <>
                <Card.Title className="text-primary">Category</Card.Title>
                <ListGroup variant="flush">
                    <Form.Group>
                        {loading ? (
                            <Loader />
                        ) : (
                            <>
                                {categories?.map(
                                    (category) =>
                                        category.parent === null && (
                                            <div key={category.id}>
                                                <Nav.Link
                                                    className={
                                                        category.id ===
                                                        activeCategory
                                                            ? "text-info fw-bold"
                                                            : category.id ===
                                                              selectedParent
                                                            ? "text-primary"
                                                            : ""
                                                    }
                                                    onClick={() =>
                                                        selectHandler(
                                                            category?.id!
                                                        )
                                                    }
                                                >
                                                    <span>{category.name}</span>
                                                </Nav.Link>
                                                {category.id ===
                                                    selectedParent &&
                                                    category?.children &&
                                                    category?.children.length >
                                                        0 && (
                                                        <Nav className="flex-column border-top border-bottom border-info h-100 pl-2">
                                                            {category?.children?.map(
                                                                (
                                                                    subcategory: Category
                                                                ) => (
                                                                    <Nav.Link
                                                                        key={
                                                                            subcategory.id
                                                                        }
                                                                        onClick={() =>
                                                                            selectHandler(
                                                                                subcategory.id!
                                                                            )
                                                                        }
                                                                    >
                                                                        <span
                                                                            className={
                                                                                subcategory.id ===
                                                                                activeCategory
                                                                                    ? "text-info fw-bold"
                                                                                    : ""
                                                                            }
                                                                        >
                                                                            {
                                                                                subcategory.name
                                                                            }
                                                                        </span>
                                                                    </Nav.Link>
                                                                )
                                                            )}
                                                        </Nav>
                                                    )}
                                            </div>
                                        )
                                )}
                            </>
                        )}
                    </Form.Group>
                </ListGroup>
            </>
        </div>
    );
};

export default ProductFilteringCategoryList;
