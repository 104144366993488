import React from "react";
import { Nav } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

interface Props {
    step1?: boolean;
    step2?: boolean;
    step3?: boolean;
    step4?: boolean;
}

const CheckoutSteps: React.FC<Props> = ({ step1, step2, step3, step4 }) => {
    return (
        <div
            className="ml-0 ml-md-3 text-center my-3 my-lg-0"
            style={{ display: "inline-block" }}
        >
            <span className="h6-sm h5">
                <LinkContainer
                    to="/login"
                    className={`d-inline ${!step1 && "disabled"}`}
                >
                    <Nav.Link>Sign In</Nav.Link>
                </LinkContainer>
                <i className="fas fa-chevron-right text-secondary" />{" "}
                <LinkContainer
                    to="/shipping"
                    className={`d-inline ${!step2 && "disabled"}`}
                >
                    <Nav.Link>Shipping</Nav.Link>
                </LinkContainer>
                <i className="fas fa-chevron-right text-secondary" />{" "}
                <LinkContainer
                    to="/payment"
                    className={`d-inline ${!step3 && "disabled"}`}
                >
                    <Nav.Link>Payment</Nav.Link>
                </LinkContainer>
                <i className="fas fa-chevron-right text-secondary" />{" "}
                <LinkContainer
                    to="/placeorder"
                    className={`d-inline ${!step4 && "disabled"}`}
                >
                    <Nav.Link>Order</Nav.Link>
                </LinkContainer>
            </span>
        </div>
    );
};

export default CheckoutSteps;
