import { addMessage } from "../actions/messageActions";

export const API_URL =
    process.env.NODE_ENV === "production" ? process.env.REACT_APP_API_URL : "/";

export const errorResponseHandler = (error: any) =>
    error.response && error.response.data.message
        ? error.response.data.message
        : error.message;

export const errorResponseHandlerNew = (error: any) => {
    return error.response && error.response.data
        ? {
              message:
                  typeof error.response.data.massage === "object"
                      ? error.response.data.massage
                      : error.message,
              messages: Array.isArray(error.response.data.message)
                  ? error.response.data.message[0].messages
                  : null,
              statusCode: error.response.data.statusCode,
              defaultMessage: error.response.error,
          }
        : { message: error.message };
};

export const addAlertHandler =
    (error: any, title: string) => (dispatch: any) => {
        const errorResponse = errorResponseHandler(error);
        if (Array.isArray(errorResponse)) {
            errorResponse.map((error) =>
                error.messages
                    ? error.messages.map((errMsg: any) =>
                          dispatch(
                              addMessage({
                                  text: errMsg.message,
                                  variant: "danger",
                                  title: title,
                              })
                          )
                      )
                    : dispatch(
                          addMessage({
                              text: error.message,
                              variant: "danger",
                              title: title,
                          })
                      )
            );
        } else {
            errorResponse.messages
                ? errorResponse.messages.map((errMsg: any) =>
                      dispatch(
                          addMessage({
                              text: errMsg.message,
                              variant: "danger",
                              title: title,
                          })
                      )
                  )
                : dispatch(
                      addMessage({
                          text: errorResponse.message,
                          variant: "danger",
                          title: title,
                      })
                  );
        }
    };

// export const addAlertHandler =
//     (error: any, title: string) => (dispatch: any) => {
//         const errorResponse = errorResponseHandlerNew(error);
//         errorResponse.messages
//             ? errorResponse.messages.map((errMsg: any) =>
//                   dispatch(
//                       addMessage({
//                           text: errMsg.message,
//                           variant: "danger",
//                           title: title,
//                       })
//                   )
//               )
//             : dispatch(
//                   addMessage({
//                       text: errorResponse.message,
//                       variant: "danger",
//                       title: title,
//                   })
//               );
//     };
