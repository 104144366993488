import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { CategoryListState } from "../../reducers/categoryReducers";
import { RootState } from "../../store";
import Loader from "../Loader";

interface Props {
    categoryId: string | number | undefined;
}

const CategoryTrail: React.FC<Props> = ({ categoryId }) => {
    const categoryList: CategoryListState = useSelector(
        (state: RootState) => state.categoryList
    );
    const { loading, error, categories } = categoryList;
    const category =
        categories &&
        categories.find(
            (currentCategory) =>
                categoryId && currentCategory.id === +categoryId
        );
    const parentCategory =
        categories &&
        categories.find(
            (currentCategory) => currentCategory.id === category?.parent?.id
        );

    return (
        <>
            {loading ? (
                <Loader variant="small" />
            ) : error ? (
                <span className="text-danger">{error}</span>
            ) : (
                category && (
                    <div className="ml-3" style={{ display: "inline-block" }}>
                        {category.parent === null ? (
                            <Link to={`/category/${category.id}`}>
                                <h6>{category.name}</h6>
                            </Link>
                        ) : (
                            <h6 className="mb-0">
                                <Link to={`/category/${parentCategory?.id}`}>
                                    {parentCategory?.name}
                                </Link>{" "}
                                <i className="fas fa-chevron-right text-secondary" />{" "}
                                <Link to={`/category/${category.id}`}>
                                    {category.name}
                                </Link>
                            </h6>
                        )}
                    </div>
                )
            )}
        </>
    );
};

export default CategoryTrail;
