import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserReviews } from "../../actions/reviewActions";
import GoBack from "../../components/layout/GoBack";
import Loader from "../../components/Loader";
import ReviewList from "../../components/review/ReviewList";
import {
    ReviewActionsState,
    ReviewListState,
} from "../../reducers/reviewReducers";
import { UserAuthState } from "../../reducers/userReducers";
import { RootState } from "../../store";

interface Props {}

const ReviewsScreen: React.FC<Props> = () => {
    const dispatch = useDispatch();
    const reviewList: ReviewListState = useSelector(
        (state: RootState) => state.reviewList
    );
    const { loading, reviews } = reviewList;

    const userAuth: UserAuthState = useSelector(
        (state: RootState) => state.userAuth
    );
    const { userInfo } = userAuth;

    const reviewActions: ReviewActionsState = useSelector(
        (state: RootState) => state.reviewActions
    );
    const { loading: actionLoading, success } = reviewActions;

    useEffect(() => {
        dispatch(getUserReviews(userInfo?.user.id));
        //eslint-disable-next-line
    }, [success]);
    return (
        <>
            <GoBack />
            <h4>Reviews</h4>
            {loading || actionLoading ? (
                <Loader />
            ) : (
                <>
                    {reviews ? (
                        <ReviewList
                            reviews={reviews}
                            loading={loading || false}
                        />
                    ) : (
                        <h5 className="text-info text-center">No Reviews</h5>
                    )}
                </>
            )}
        </>
    );
};

export default ReviewsScreen;
