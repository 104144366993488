import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, match, RouteComponentProps } from "react-router-dom";
import { Row, Col, ListGroup, Image, Form, Button } from "react-bootstrap";
import { addToCart, removeFromCart } from "../../actions/cartActions";
import GoBack from "../../components/layout/GoBack";
import { CartState } from "../../reducers/cartReducers";
import { RootState } from "../../store";

interface Props extends RouteComponentProps {
    match: match<{ id?: string | undefined }>;
}

const CartScreen: React.FC<Props> = ({ match, location, history }) => {
    const productId = match.params.id;
    const qty: number = location.search
        ? Number(location.search.split("=")[1])
        : 1;
    const dispatch = useDispatch();
    const cart: CartState = useSelector((state: RootState) => state.cart);
    const { cartItems } = cart;
    useEffect(() => {
        if (productId) {
            dispatch(addToCart(productId, qty));
            history.push("/cart/");
        }
    }, [dispatch, productId, qty, history]);

    const removeFromCartHandler = (id: number) => {
        dispatch(removeFromCart(id));
    };

    const checkOutHandler = () => {
        history.push("/login?redirect=shipping");
    };

    return (
        <>
            <GoBack />
            <h3>Shopping Cart</h3>
            <Row>
                <Col lg={8} md={9} className="mb-3">
                    {cartItems.length === 0 ? (
                        <strong className="text-info">
                            Your cart is empty
                        </strong>
                    ) : (
                        <ListGroup variant="flush" className="text-center">
                            {cartItems.map((item) => (
                                <ListGroup.Item key={item.product}>
                                    <Row className=" d-flex align-items-center">
                                        <Col
                                            md={2}
                                            xs={10}
                                            className="p-1 mb-md-0 mb-sm-3 mx-auto"
                                        >
                                            <Image
                                                src={item.image}
                                                alt={item.name}
                                                fluid
                                                rounded
                                            />
                                        </Col>
                                        <Col
                                            md={5}
                                            className="mb-md-0 mb-sm-4 mb-3"
                                        >
                                            <Link
                                                to={`/product/${item.product}`}
                                            >
                                                {item.name}
                                            </Link>
                                        </Col>

                                        <Col
                                            md={2}
                                            xs={4}
                                            className="px-lg-3 px-md-1 px-3"
                                        >
                                            ${item.price}
                                        </Col>
                                        <Col
                                            md={2}
                                            xs={4}
                                            className="px-2 px-lg-2 px-md-2"
                                        >
                                            <Form.Control
                                                as="select"
                                                value={item.qty}
                                                onChange={(e) =>
                                                    dispatch(
                                                        addToCart(
                                                            item.product,
                                                            +e.target.value
                                                        )
                                                    )
                                                }
                                                className="bg-dark text-light border-0"
                                            >
                                                {[...Array(item.stock).keys()]
                                                    .slice(0, 20)
                                                    .map((x) => (
                                                        <option
                                                            key={x + 1}
                                                            value={x + 1}
                                                        >
                                                            {x + 1}
                                                        </option>
                                                    ))}
                                            </Form.Control>
                                        </Col>
                                        <Col
                                            md={1}
                                            xs={4}
                                            className="px-2 px-lg-2 px-md-0"
                                        >
                                            <Button
                                                type="button"
                                                variant="danger"
                                                onClick={() =>
                                                    removeFromCartHandler(
                                                        item.product!
                                                    )
                                                }
                                                style={{ height: "36.5px" }}
                                            >
                                                <i className="fas fa-trash"></i>
                                            </Button>
                                        </Col>
                                    </Row>
                                </ListGroup.Item>
                            ))}
                        </ListGroup>
                    )}
                </Col>
                <Col lg={4} md={3}>
                    <ListGroup variant="flush">
                        <ListGroup.Item>
                            <h6>
                                Subtotal (
                                {cartItems.reduce(
                                    (acc, item) => acc + item.qty!,
                                    0
                                )}
                                ) items
                            </h6>
                            $
                            {cartItems
                                .reduce(
                                    (acc, item) =>
                                        acc + item.qty! * item.price!,
                                    0
                                )
                                .toFixed(2)}
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <Button
                                type="button"
                                className="btn-block"
                                disabled={cartItems.length === 0}
                                onClick={checkOutHandler}
                            >
                                Proceed to Checkout
                            </Button>
                        </ListGroup.Item>
                    </ListGroup>
                </Col>
            </Row>
        </>
    );
};

export default CartScreen;
