import axios from "axios";
import {
    CATEGORY_ACTION_RESET,
    CATEGORY_CREATE_FAIL,
    CATEGORY_CREATE_REQUEST,
    CATEGORY_CREATE_SUCCESS,
    CATEGORY_DELETE_FAIL,
    CATEGORY_DELETE_REQUEST,
    CATEGORY_DELETE_SUCCESS,
    CATEGORY_DETAILS_FAIL,
    CATEGORY_DETAILS_REQUEST,
    CATEGORY_DETAILS_RESET,
    CATEGORY_DETAILS_SUCCESS,
    CATEGORY_LIST_FAIL,
    CATEGORY_LIST_REQUEST,
    CATEGORY_LIST_SUCCESS,
    CATEGORY_UPDATE_FAIL,
    CATEGORY_UPDATE_REQUEST,
    CATEGORY_UPDATE_SUCCESS,
} from "../constants/categoryContants";
import { API_URL, errorResponseHandler } from "../constants/helpers";
import {
    AsyncActionType,
    Category,
    GeneralActionType,
} from "../constants/types";
import { addMessage } from "./messageActions";

export const listCategories: AsyncActionType = () => async (dispatch) => {
    try {
        dispatch({ type: CATEGORY_LIST_REQUEST });
        const { data } = await axios.get(
            `${API_URL}categories?_sort=listOrder:ASC`
        );
        dispatch({
            type: CATEGORY_LIST_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: CATEGORY_LIST_FAIL,
            payload: errorResponseHandler(error),
        });
        dispatch(
            addMessage({
                text: errorResponseHandler(error),
                variant: "danger",
                title: "Category List Error",
            })
        );
    }
};

export const getCategoryDetails: AsyncActionType =
    (id: number) => async (dispatch) => {
        try {
            dispatch({ type: CATEGORY_DETAILS_REQUEST });
            const { data } = await axios.get(`${API_URL}categories/${id}`);
            dispatch({
                type: CATEGORY_DETAILS_SUCCESS,
                payload: data,
            });
        } catch (error) {
            dispatch({
                type: CATEGORY_DETAILS_FAIL,
                payload: errorResponseHandler(error),
            });
            dispatch(
                addMessage({
                    text: errorResponseHandler(error),
                    variant: "danger",
                    title: "Category Details Error",
                })
            );
        }
    };

export const updateCategory: AsyncActionType =
    (id: number, updatedCategory: Category) => async (dispatch, getState) => {
        try {
            dispatch({ type: CATEGORY_UPDATE_REQUEST });
            const {
                userAuth: { userInfo },
            } = getState();

            const config = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${userInfo?.jwt}`,
                },
            };

            const { data } = await axios.put(
                `${API_URL}categories/${id}`,
                updatedCategory,
                config
            );
            dispatch({
                type: CATEGORY_UPDATE_SUCCESS,
                payload: data,
            });
            dispatch(listCategories());
        } catch (error) {
            dispatch({
                type: CATEGORY_UPDATE_FAIL,
                payload: errorResponseHandler(error),
            });
            dispatch(
                addMessage({
                    text: errorResponseHandler(error),
                    variant: "danger",
                    title: "Category Update Error",
                })
            );
        }
    };

export const createCategory: AsyncActionType =
    (category: Category) => async (dispatch, getState) => {
        try {
            dispatch({ type: CATEGORY_CREATE_REQUEST });
            const {
                userAuth: { userInfo },
            } = getState();

            const config = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${userInfo?.jwt}`,
                },
            };

            const { data } = await axios.post(
                `${API_URL}categories`,
                category,
                config
            );
            dispatch({
                type: CATEGORY_CREATE_SUCCESS,
                payload: data,
            });
            dispatch(listCategories());
        } catch (error) {
            dispatch({
                type: CATEGORY_CREATE_FAIL,
                payload: errorResponseHandler(error),
            });
            dispatch(
                addMessage({
                    text: errorResponseHandler(error),
                    variant: "danger",
                    title: "Category Create Error",
                })
            );
        }
    };

export const deleteCategory: AsyncActionType =
    (id: number) => async (dispatch, getState) => {
        try {
            dispatch({ type: CATEGORY_DELETE_REQUEST });
            const {
                userAuth: { userInfo },
            } = getState();

            const config = {
                headers: {
                    Authorization: `Bearer ${userInfo?.jwt}`,
                },
            };
            const { data } = await axios.delete(
                `${API_URL}categories/${id}`,
                config
            );
            dispatch({
                type: CATEGORY_DELETE_SUCCESS,
                payload: data,
            });
            dispatch(listCategories());
        } catch (error) {
            dispatch({
                type: CATEGORY_DELETE_FAIL,
                payload: errorResponseHandler(error),
            });
            dispatch(
                addMessage({
                    text: errorResponseHandler(error),
                    variant: "danger",
                    title: "Category Delete Error",
                })
            );
        }
    };

export const resetCategoryDetails: GeneralActionType = () => (dispatch) => {
    dispatch({ type: CATEGORY_DETAILS_RESET });
};

export const resetCategoryAction: GeneralActionType = () => (dispatch) => {
    dispatch({
        type: CATEGORY_ACTION_RESET,
    });
};
