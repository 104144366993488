import React, { Fragment, useEffect, useState } from "react";
import { Col, Pagination } from "react-bootstrap";
import { useSelector } from "react-redux";
import { FilteringState } from "../../reducers/filteringReducers";
import { ProductListState } from "../../reducers/productReducers";
import { RootState } from "../../store";
import Loader from "../Loader";

interface Props {
    currentPage: number;
    setCurrentPage: (page: number) => void;
    hideOnMobile?: boolean;
}

const ProductPagination: React.FC<Props> = ({
    currentPage,
    setCurrentPage,
    hideOnMobile = false,
}) => {
    const filtering: FilteringState = useSelector(
        (state: RootState) => state.filtering
    );
    const { filters } = filtering;

    const productList: ProductListState = useSelector(
        (state: RootState) => state.productList
    );
    const { count, loading, products } = productList;

    const [hasPages, setHasPages] = useState(false);
    const [totalPages, setTotalPages] = useState(1);

    useEffect(() => {
        if (loading && !count) {
            setHasPages(false);
        } else if (count) {
            setHasPages(count > filters._limit);
            setTotalPages(Math.ceil(count / filters._limit));
        }
    }, [filters, count, loading]);

    const goPreviousPage = () => {
        setCurrentPage(currentPage - 1);
    };
    const goNextPage = () => {
        setCurrentPage(currentPage + 1);
    };

    const getPaginationGroup = () => {
        return new Array(totalPages).fill(1).map((_, index) => index + 1);
    };

    const PaginationComponent = () => (
        <Pagination className="mb-0 d-flex justify-content-center">
            <Pagination.Prev
                onClick={goPreviousPage}
                disabled={currentPage === 1}
            />
            <Pagination.Item
                onClick={(e) => setCurrentPage(1)}
                active={currentPage === 1}
            >
                {1}
            </Pagination.Item>
            {currentPage > 1 + 3 && <Pagination.Ellipsis />}
            {getPaginationGroup().map((pageNumber) => (
                <Fragment key={pageNumber}>
                    {pageNumber > 1 &&
                        pageNumber < totalPages &&
                        pageNumber < currentPage + 3 &&
                        pageNumber > currentPage - 3 && (
                            <Pagination.Item
                                onClick={(e) => setCurrentPage(pageNumber)}
                                active={currentPage === pageNumber}
                                className={`${
                                    (pageNumber === currentPage + 2 ||
                                        pageNumber === currentPage - 2) &&
                                    "d-none d-md-flex"
                                }`}
                            >
                                {pageNumber}
                            </Pagination.Item>
                        )}
                </Fragment>
            ))}
            {currentPage < totalPages - 3 && <Pagination.Ellipsis />}
            <Pagination.Item
                onClick={(e) => setCurrentPage(totalPages)}
                active={currentPage === totalPages}
            >
                {totalPages}
            </Pagination.Item>
            <Pagination.Next
                onClick={goNextPage}
                disabled={currentPage === totalPages}
            />
        </Pagination>
    );

    useEffect(() => {
        setCurrentPage(1);
        //eslint-disable-next-line
    }, [filters._limit, count]);

    return (
        <>
            <Col
                md={8}
                lg={6}
                className={`${
                    hideOnMobile ? "d-none d-md-flex" : "d-flex"
                } justify-content-center d-md-block`}
            >
                {hasPages && <PaginationComponent />}
            </Col>
            <Col lg={3} className="d-none d-lg-block text-right">
                {loading ? (
                    <Loader variant="small" />
                ) : (
                    products != null && (
                        <h6 className="text-info mb-0">{`${
                            (currentPage - 1) * filters._limit + 1
                        } - ${
                            (currentPage - 1) * filters._limit + products.length
                        } / ${count}`}</h6>
                    )
                )}
            </Col>
        </>
    );
};

export default ProductPagination;
