import React, {Fragment} from 'react'
import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Category, Product } from '../../constants/types';
import ImageThumbnailHandler from '../images/ImageThumbnailHandler';

interface Props {
    favorites: Product[],
    categories: Category[]
}

const FavoritesList: React.FC<Props> = ({favorites, categories}) => {
    return (
        <Table
                        striped
                        bordered
                        hover
                        responsive
                        className="table-sm text-center mt-3 table-vcenter"
                    >
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>NAME</th>
                                <th>PRICE</th>
                                <th>STOCK</th>
                                <th colSpan={2}>CATEGORY</th>
                                <th className="d-none d-md-table-cell">
                                    BRAND
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {favorites != null &&
                                favorites?.map((product) => (
                                    <tr key={product.id}>
                                        <td>{product.id}</td>
                                        <td>
                                            <Link to={`/product/${product.id}`}>
                                                {product.name}
                                            </Link>
                                        </td>
                                        <td>{product.price} $</td>
                                        <td>
                                            {product.stock === 0 ? (
                                                <i
                                                    className="fas fa-times"
                                                    style={{ color: "red" }}
                                                />
                                            ) : (
                                                <i
                                                    className="fas fa-check"
                                                    style={{ color: "green" }}
                                                />
                                            )}
                                        </td>
                                        {categories.map((category) => {
                                            if (
                                                category.id === product.category
                                            ) {
                                                return (
                                                    <Fragment key={category.id}>
                                                        <td
                                                            style={{
                                                                maxWidth:
                                                                    "fit-content",
                                                            }}
                                                        >
                                                            <Link
                                                                to={`/product/${product.id}`}
                                                            >
                                                                <ImageThumbnailHandler
                                                                    itemImages={
                                                                        category.image
                                                                            ? [
                                                                                  category.image,
                                                                              ]
                                                                            : null
                                                                    }
                                                                />
                                                            </Link>
                                                        </td>
                                                        <td>{category.name}</td>
                                                    </Fragment>
                                                );
                                            } else return null;
                                        })}

                                        <td className="d-none d-md-table-cell">
                                            {product.brand}
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </Table>
    )
}

export default FavoritesList
