import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import { Navbar, Nav, Container } from "react-bootstrap";
import { getMyDetails, logout } from "../../actions/userActions";
import { UserAuthState } from "../../reducers/userReducers";
import Subheader from "../subheader/Subheader";
import UserDropdown from "./UserDropdown";
import AdminPanelDropdown from "./AdminPanelDropdown";
import { RootState } from "../../store";
import SearchBar from "./SearchBar";

const Header = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    let history = useHistory();
    const userAuth: UserAuthState = useSelector(
        (state: RootState) => state.userAuth
    );
    const { userInfo } = userAuth;
    useEffect(() => {
        if (userInfo) {
            dispatch(getMyDetails());
        }
        // eslint-disable-next-line
    }, []);

    const logoutHandler = () => {
        dispatch(logout());
        history.push("/");
    };
    return (
        <header>
            <Navbar bg="dark" variant="dark" expand="lg" collapseOnSelect>
                <Container>
                    <LinkContainer to="/">
                        <Navbar.Brand>ProShop</Navbar.Brand>
                    </LinkContainer>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="ml-auto">
                            <SearchBar />
                            <LinkContainer to="/cart">
                                <Nav.Link
                                    active={location.pathname === "/cart"}
                                >
                                    <i className="fas fa-shopping-cart" /> Cart
                                </Nav.Link>
                            </LinkContainer>
                            {userInfo ? (
                                <UserDropdown
                                    userName={userInfo.user?.name || "Profile"}
                                    logoutHandler={logoutHandler}
                                    location={location}
                                />
                            ) : (
                                <LinkContainer to="/login">
                                    <Nav.Link
                                        active={location.pathname === "/login"}
                                    >
                                        <i className="fas fa-user" /> Sign In
                                    </Nav.Link>
                                </LinkContainer>
                            )}
                            {userInfo &&
                                userInfo.user?.role?.type === "admin" && (
                                    <AdminPanelDropdown location={location} />
                                )}
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <Subheader />
        </header>
    );
};

export default Header;
