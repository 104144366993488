import React from "react";
import { NavDropdown } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

interface Props {
    logoutHandler: () => void;
    userName: string;
    location: { pathname: string };
}

const UserDropdown: React.FC<Props> = ({
    logoutHandler,
    userName,
    location,
}) => {
    return (
        <NavDropdown title={userName} id="username">
            <LinkContainer to="/profile">
                <NavDropdown.Item active={location.pathname === "/profile"}>
                    Profile
                </NavDropdown.Item>
            </LinkContainer>
            <LinkContainer to="/favorites">
                <NavDropdown.Item active={location.pathname === "/favorites"}>
                    Favorites
                </NavDropdown.Item>
            </LinkContainer>
            <LinkContainer to="/reviews">
                <NavDropdown.Item active={location.pathname === "/reviews"}>
                    Reviews
                </NavDropdown.Item>
            </LinkContainer>
            <NavDropdown.Item onClick={logoutHandler}>Logout</NavDropdown.Item>
        </NavDropdown>
    );
};

export default UserDropdown;
