import React, { useState, useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "react-bootstrap";
import Loader from "../../components/Loader";
import { getMyDetails, updateMe } from "../../actions/userActions";
import {
    UserActionsState,
    UserAuthState,
    UserDetailsState,
} from "../../reducers/userReducers";
import { OrderListMyState } from "../../reducers/orderReducers";
import { getOrderListMy, resetOrderListMy } from "../../actions/orderActions";
import ProfileEdit from "../../components/profile/ProfileEdit";
import ProfileOrders from "../../components/profile/ProfileOrders";
import { RootState } from "../../store";

interface Props extends RouteComponentProps {}

const ProfileScreen: React.FC<Props> = ({ location, history }) => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [username, setUsername] = useState("");
    const [phone, setPhone] = useState("");

    const dispatch = useDispatch();

    const userDetails: UserDetailsState = useSelector(
        (state: RootState) => state.userDetails
    );
    const { loading, error, user } = userDetails;

    const userActions: UserActionsState = useSelector(
        (state: RootState) => state.userActions
    );
    const { loading: loadingAction } = userActions;

    const userAuth: UserAuthState = useSelector(
        (state: RootState) => state.userAuth
    );
    const { userInfo } = userAuth;

    const orderListMy: OrderListMyState = useSelector(
        (state: RootState) => state.orderListMy
    );
    const { loading: loadingOrders, error: errorOrders, orders } = orderListMy;

    useEffect(() => {
        if (!userInfo) {
            history.push("/login");
        } else {
            if (userInfo.user?.id !== user?.id) {
                dispatch(getMyDetails());
                dispatch(resetOrderListMy());
                dispatch(getOrderListMy());
            } else {
                setName(user?.name || "");
                setUsername(user?.username || "");
                setEmail(user?.email || "");
                setPhone(user?.phone || "");
                dispatch(getOrderListMy());
            }
        }
    }, [dispatch, history, user, userInfo]);

    const submitHandler = (e: React.FormEvent<HTMLElement>) => {
        e.preventDefault();
        dispatch(updateMe(name, username, email, phone));
    };
    return (
        <>
            <Row>
                <Col md={3}>
                    <Row>
                        <h4 className="mb-4">User Profile</h4>
                    </Row>
                    {loadingAction || loading ? (
                        <Loader />
                    ) : error ? (
                        <h2 className="text-danger">{error}</h2>
                    ) : (
                        <ProfileEdit
                            name={name}
                            setName={setName}
                            username={username}
                            setUsername={setUsername}
                            email={email}
                            setEmail={setEmail}
                            phone={phone}
                            setPhone={setPhone}
                            submitHandler={submitHandler}
                        />
                    )}
                </Col>
                <Col md={9}>
                    <Row>
                        <h4 className="mb-4 mt-4 mt-md-0">My Orders</h4>
                    </Row>
                    {loadingOrders ? (
                        <Loader />
                    ) : errorOrders ? (
                        <h2 className="text-danger">{errorOrders}</h2>
                    ) : orders != null && orders?.length > 0 ? (
                        <ProfileOrders orders={orders} />
                    ) : (
                        <h2 className="text-info">You have no Orders</h2>
                    )}
                </Col>
            </Row>
        </>
    );
};

export default ProfileScreen;
