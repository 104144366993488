import React, { useEffect, useState } from "react";
import { Image as ImageComponent } from "react-bootstrap";
import Loader from "../Loader";

interface Props {
    src: string;
    alt: string;
    loadingSrc?: string;
    className?: string;
    fluid?: boolean;
    onClick?: (e: any) => void;
    style?: {};
}

const ImageWithLoading: React.FC<Props> = ({
    src,
    alt,
    loadingSrc,
    className,
    fluid = true,
    onClick,
    style,
}) => {
    const [loadedImage, setLoadedImage] = useState<string | null>(
        loadingSrc || null
    );

    useEffect(() => {
        let Img = new Image();
        Img.src = src;
        Img.onload = () => {
            setLoadedImage(Img.src);
        };
        return () => {
            Img.onload = null;
        };
    }, [src]);

    return (
        <>
            {loadedImage === null ? (
                <Loader />
            ) : (
                <ImageComponent
                    src={loadedImage}
                    alt={alt}
                    fluid={fluid}
                    className={className}
                    onClick={onClick}
                    style={style}
                />
            )}
        </>
    );
};

export default ImageWithLoading;
