import React from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { addMessage } from "../actions/messageActions";
import { createProduct } from "../actions/productActions";
import { Product } from "../constants/types";
import { CategoryListState } from "../reducers/categoryReducers";
import { v4 } from "uuid";
import { RootState } from "../store";

const Test = () => {
    const dispatch = useDispatch();

    const categoryList: CategoryListState = useSelector(
        (state: RootState) => state.categoryList
    );
    const { categories } = categoryList;

    const testMessageClickHandler = () => {
        let message1 = {
            text: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Tempora corrupti consequatur autem praesentium expedita asperiores?",
            variant: "info",
        };
        let message2 = {
            text: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quo voluptatibus esse, facere dicta aliquid excepturi.",
            title: "Test error",
        };
        let message3 = {
            text: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quam atque alias vitae, dicta accusamus nostrum?",
            variant: "success",
        };
        dispatch(addMessage(message1));
        dispatch(addMessage(message2));
        dispatch(addMessage(message3));
        dispatch(addMessage(message2));
    };

    const createTestProductClickHandler = (iterations = 10) => {
        if (categories) {
            for (let i = 0; i <= iterations; i++) {
                let selectedCategory =
                    categories[Math.floor(Math.random() * categories.length)];
                let newProduct: Product = {
                    name: `${selectedCategory.name} Product ${v4().slice(
                        0,
                        8
                    )}`,
                    price: Math.floor(Math.random() * 1000),
                    category: selectedCategory.id,
                    code: "TESTPRO",
                    stock: Math.floor(Math.random() * 15),
                    description: "Lorem Ipsum Test Product",
                    images: selectedCategory.image
                        ? [selectedCategory.image]
                        : null,
                };
                console.log(newProduct);
                dispatch(createProduct(newProduct));
            }
        }
    };

    return (
        <div>
            <h1>TEST</h1>
            <Button onClick={(e) => testMessageClickHandler()}>
                TEST MESSAGE
            </Button>
            <hr />
            <Button onClick={(e) => createTestProductClickHandler()}>
                ADD TEST PRODUCT
            </Button>
        </div>
    );
};

export default Test;
